const globals = {
    allRightsReserved: 'All Rights Reserved',
    errors: {
        404: {
            title: 'Looks like there is nothing here. Are you lost?',
            button: 'Go to Home Page',
        },
    },

    slug: "Your profile will be on ikonstar.com/{slug}",

    specialUnlock: "Reserve you seat",
    accountNotSetupLive: "You need to finish the tutorial to use the live streaming!",

    confirmationDelete: "Are you sure?<br>You won't be able to revert this!",
    remove: 'Remove',
    tryAgain: 'Try again',
    sendComment: 'Comment',
    deleteIt: 'Yes, delete it!',
    language: 'Language',
    previous: 'Previous',
    next: 'Next',
    start: 'Start',
    hours: "hours",
    minutes: "minutes",
    no: 'No',
    yes: 'Yes',
    submit: "Submit",
    close: "Close",
    enabled: 'Enabled',
    disabled: 'Disabled',
    loading: 'Loading...',
    noMore: 'No more content',
    loadMore: 'Load more',
    uploadingMedia: 'Uploading media',
    clickToClose: "Click anywhere to close",
    details: "Details",
    backToProfile: "Back to my profile",
    filters: "Filters",

    noActionPreviewMode: "You cannot do this in preview mode :)",

    countdown: {
        days: 'Days',
        hours: 'Hours',
        minutes: 'Minutes',
        seconds: 'Seconds',
    },

    menu: {
        sidebar: {
            newProject: 'New project...',
            settings: 'Settings',
            profile: 'Profile',
            preview: 'Preview',
            notification: 'Notifications',
            signOut: 'Sign Out',
            homepage: 'Back to Home',
        },
    },
    notification: {
        unreadMessages: 'Unread messages',
        markAllAsRead: 'Mark {number} as read',
        markAsRead: 'Mark as read',
        delete: 'Delete',
        preferences: 'View preferences',
        switchRead: 'Only show unread',
    },
    edit: 'Edit',
    addMore: 'Add more',
    seeMore: 'see more',
    exit: 'Exit',
    validations: {
        email: 'Email must be a valid email',
        required: 'The field is required',
        number: 'The field is not a valid numeric value',
        date: 'The field is not a date value',
        string: 'The field is not a valid string',
        min: 'The field requires at least {min} characters',
        numberMin: 'The field must be greater than {min}',
        max: 'The field allows at most {max} characters',
        numberMax: 'The field must be lower than {max}',
        recaptcha: "Please verify you are human",
    },
    form: {
        validations: {
            email: "{label} is not a valid email",
            required: "{label} is required",
            requiredSelect: "Please choose a {label}",
            number: '{label} is not a valid numeric value',
            date: '{label} is not a valid date value',
            string: '{label} is not a valid string',
            numberMin: '{label} must be greater than {min}',
            numberMax: '{label} must be lower than {max}',
            min: "{label} must contain at least {min} characters",
            max: "{label} cannot contain more than {max} characters",
            recaptcha: "Please verify that you are a human"
        },
        fields: {
            email: {
                label: "Email Address",
            },
            fullname: {
                label: "Full Name",
            },
            username: {
                label: "Username",
            },
            password: {
                label: "Password",
            },
            title: {
                label: "Title",
            },
            date: {
                label: "Date",
            },
            photo: {
                label: "Photo",
            },
            comment: {
                "label": "Comment"
            }
        }
    },
    date: {
        today: 'Today',
        yesterday: 'Yesterday',
        at: '{date} at {time}',
        minutes: "minutes",
    },
    requiredInputGuide: 'Inputs with * are required',
    title: {
        'user-dashboard': '',
        'user-account': 'My account',
        'user-post-view': 'Post',
        'user-messages': 'My messages',
        'user-my-calendar': 'My personnal planning',
        'user-calendar-add-event': 'Add exercices',
        'user-private-video-call-event': 'Book a video call',
        'user-calendar-congrats': 'My calendar event',
        'user-workouts': 'My Workouts',
        'user-view-workouts': 'My Workouts',
        'user-masterclass': 'Masterclasses',
        'user-view-masterclass': 'Masterclasses',
        'user-view-masterclass-episode': 'Masterclasses',
        'user-progress': 'My progress',
        'user-favorites': 'My favorites',
        'user-gallery': 'My gallery',
        'user-recipes': 'My recipes',
        'user-view-recipe': 'My recipes',
        'user-workout-program': 'Workout Program',

        'kol-admin-dashboard': '',
        'kol-admin-my-page': '',
        'kol-admin-my-feed': 'My Feed',
        'kol-admin-my-settings': 'Your profile settings',
        'kol-admin-messages': 'My messages',
        'kol-admin-my-calendar': 'My personnal planning',
        'kol-admin-calendar-add-event': 'Add custom event',
        'kol-admin-my-workouts': 'My Workouts',
        'kol-admin-edit-workout': 'Edit a Workout',
        'kol-admin-view-workout-episodes': 'My episodes',
        'kol-admin-edit-workout-episode': 'Edit an episode',
        'kol-admin-my-masterclass': 'Masterclasses',
        'kol-admin-edit-masterclass': 'Edit a masterclass',
        'kol-admin-view-masterclass-episodes': 'My episodes',
        'kol-admin-edit-masterclass-episode': 'Edit an episode',
        'kol-admin-medias-management': 'My medias',
    },

    tagLine: 'The unique platform for content creators',
    product: {
        title: 'PRODUCT',
        forIkons: 'For ikons',
        forFans: 'For fans',
        forCorp: 'For corporations',
        become: 'Become a ikon',
        download: 'Download ikonstar',
    },
    blog: {
        title: 'BLOG',
        fitness: 'Fitness',
        food: 'Food',
        health: 'Health',
        workouts: 'Workouts',
        nutrition: 'Nutritions',
    },
    support: {
        title: 'SUPPORT',
        helpCenter: 'Help center',
        contact: 'Contact',
    },
    about: {
        title: 'ABOUT',
    },

    global: {
        videoStatus: {
            processing: 'Video is processing',
            pending: 'Video is pending',
            failed: 'Video is failed',
        },

        form: {
            button: {
                submit: {
                    label: 'Submit',
                }
            }
        }
    },

    plans: {
        intervals: {
            monthly: 'Monthly Plan',
            quarterly: 'Quarterly Plan',
            yearly: 'Yearly Plan',
        },
        recurring: {
            monthly: 'month',
            quarterly: 'quarter',
            yearly: 'year',
        }
    },

    enums: {
        "categories": {
            "aerobics": "Aerobics",
            "aqua-fitness": "Aqua Fitness",
            "boxing": "Boxing",
            "cardio": "Cardio",
            "circuit-training": "Circuit Training",
            "cross-fit": "CrossFit",
            "dance-fitness": "Dance Fitness",
            "hiit": "HIIT",
            "martial-arts": "Martial Arts",
            "pilates": "Pilates",
            "strength-training": "Strength Training",
            "yoga": "Yoga",
        },
        "body-parts": {
            "arms": "Arms",
            "legs": "Legs",
            "chest": "Chest",
            "back": "Back",
            "abdomen": "Abdomen",
            "shoulders": "Shoulders",
            "glutes": "Glutes",
            "full-body": "Full Body",
            "neck": "Neck",
            "wrist": "Wrist",
            "ankle": "Ankle",
        },
        "goals": {
            "health": "Health",
            "burning-fat": "Burning Fat",
            "building-muscle": "Building Muscle",
            "increasing-endurance": "Increasing Endurance",
            "improving-mobility": "Improving Mobility",
            "enhancing-flexibility": "Enhancing Flexibility",
            "stress-relief": "Stress Relief",
            "weight-loss": "Weight Loss",
            "posture-improvement": "Posture Improvement",
            "body-toning": "Body Toning",
        },
        "levels": {
            "hard": "Difficult",
            "easy": "Easy",
            "beginner": "Beginner",
            "intermediate": "Intermediate",
            "advanced": "Advanced",
            "professional": "Professional",
            "elderly": "Elderly",
            "for-all": "For all",
        },
        "locations": {
            "indoor": "Indoor",
            "outdoor": "Outdoor",
            "gym": "Gym",
            "home": "Home",
            "office": "Office",
            "swimming-pool": "Swimming Pool",
            "fitness-studio": "Fitness Studio",
        },
    },

    superAdmin: {
        form: {
            search: {
                label: 'Search',
                placeholder: 'Search...',
            },

            button: {
                resetFilters: {
                    label: 'Reset filters',
                },
            },
        },
    },
    pwa: {
        title: "Install the app to receive notifications",
        description: "Install {0} on your iOS device! Tap share and then {1}",
        addToHome: "Add to Home Screen",
        updateInfo: "A new version of Ikonstar is available! If you're doing important work, you can refresh your browser later.",
        updateNow: "Update Now",
        updateLater: "I'll refresh later",
    },
    messengerSubscriber: "This module is only available for Subscribers.",
    noMobileModule: "This module is only available on Desktop or Tablets.",
    previewMode: "You are now in preview mode. Click here to stop.",
    stopPreviewMode: "Please first stop preview mode.",
    impersonating: "You are now impersonating {username}. Click here to stop",

    share: {
        title: 'Share',
        copy: 'Copy',
        success: 'Link copied to clipboard',
    },

    string: {
        success: 'Copied to clipboard',
    },

    tooltips: {
        verifiedAccount: 'Verified Account',
        like: 'Like',
        share: 'Share',
        deletePreviewLink: 'Remove link preview',
        disablePreviewLink: 'Disable link preview',
        totalViews: 'Total views',
    },

    leave: {
        title: 'Do you really want to leave? you have unsaved changes!',
        stay: 'Stay',
        leave: 'Leave'
    },
    modules: {
        workouts: 'Targeted exercise session and training program.',
        gallery: 'Sharing photos among your members and yourself.',
        recipes: 'Complete Workout Recipe Set',
        polls: 'Include polls in your posts for your members.',
        'private-video-calls': 'Private live calls to your members.',
        masterclass: 'Lessons modules to share your skills and knowledge',
        podcasts: 'Informative audio/video series covering diverse topics',
        'live-events': 'Live streaming events with ticketing system'
    },

    draft: {
        title: 'Drafts',
        helper: 'your drafts are only available on this device',
        noItems: "you don't have drafts stored",
        noTitle: 'Not started...',
        button: 'Drafts list',
        continue: 'Continue',
        deleteSuccess: 'Your draft was deleted successfully!',
        deleteError: "Your draft wasn't deleted, try again!",
    },

    search: {
        landingTitle: "Are you looking for {gold} ?{br}Get started here!",
        landingTitleGold: "Ikonstar Creators",
        landingSubtitle: "Type in their username and get access to{br}their exclusive content",
        input: {
            placeholder: "Find a creator"
        },
        allResults: "See all results"
    },

    feed: {
        forYou: "For You",
        following: "Following"
    },

    signup: {
        title: "Create an account"
    }
}

export default globals
