import { permission } from "process"

const creator = {
    search: 'Search',
    searchHelper: 'Type something to search',
    menu: {
        dashboard: 'Dashboard',
        openPage: 'Open My Page',
        myPage: 'My Page',
        mySettings: 'My Settings',
        myWorkouts: 'Workouts',
        myWorkoutPrograms: 'Programs',
        masterclasses: 'Masterclass',
        podcasts: 'Podcasts',
        liveEvents: 'Live Events',
        myPlans: 'Plan Prices',
        messages: 'Messenger',
        medias: 'Medias',
        gallery: 'Gallery',
        recipes: 'Recipes',
        creators: 'My creators',
        subscribers: 'My subscribers',
        followers: 'My followers',
        accountManager: {
            management: "Management",
            posts: "Posts",
        }
    },
    creator: {
        title: {
            settings: 'Your profile settings',
        },
        form: {
            menu: {
                language: 'Preferred language',
                personal: 'Personal infos',
                cover: 'Cover image',
                landing: 'Landing video',
                password: 'Password',
                social: 'Social networks',
                module: 'Modules',
                messenger: 'Messenger',
                availability: 'Availability',
                plan: 'My plan',
                perk: 'My plans perks',
                video: 'Private video calls',
                faq: 'My Faqs',
            },
            avatar: {
                edit: 'Edit avatar',
                description: 'Avatar sizes in pixels: minimum 200x200 - maximum 1080x1080',
                message: {
                    success: 'Your avatar is successfully uploaded.',
                },
            },
            headerImg: {
                edit: 'Edit header image',
                message: {
                    success: 'Your header image is successfully uploaded.',
                    removeConfirmation: 'Are you sure you want to remove your header image?',
                    removeSuccess: 'Your header image is successfully removed.',
                    removeError: 'There was an error while removing your header image.',
                },
                button: {
                    remove: 'Remove',
                },
            },
            personalInfo: {
                title: 'Your personal information',
                contactUsIfYouSpotAMistake: 'Contact us if you spot a mistake',
                username: {
                    placeholder: 'Username',
                    label: 'Username',
                },
                slug: {
                    placeholder: 'Slug',
                    label: 'Slug',
                    formatError:
                        'Your slug needs to start with letters, can only contain lowercase letters, numbers and dashes.',
                },
                fullName: {
                    placeholder: 'Full name',
                    label: 'Full name',
                },
                email: {
                    placeholder: 'Email',
                    label: 'Email',
                },
                about: {
                    placeholder: 'About',
                    label: 'About',
                },
                bio: {
                    placeholder: 'Bio',
                    label: 'Bio',
                },
                birthdate: {
                    placeholder: 'Birth date',
                    label: 'Birth date',
                },
                gender: {
                    placeholder: 'Gender',
                    label: 'Gender',
                },
                phone: {
                    placeholder: 'Phone',
                    label: 'Phone',
                },
                website: {
                    placeholder: 'Website',
                    label: 'Website',
                },
                button: {
                    submit: {
                        label: 'Save my personal information',
                    },
                },
                message: {
                    success: 'Your personal information is successfully updated.',
                },
            },
            landingVideo: {
                title: 'Your landing page video',
                uploadVideo: {
                    label: 'Upload video',
                },
                currentVideo: {
                    label: 'Current video',
                },
                remove: {
                    label: 'Remove landing video',
                },
                button: {
                    submit: {
                        label: 'Upload your landing page video',
                    },
                    removeConfirmation: {
                        label: 'Remove',
                    },
                },
                message: {
                    noFileSelected: 'Please select at least one video file',
                    error: 'There was an error uploading your video',
                    success: 'Your video has been uploaded successfully and is now being processed.',
                    noVideoToRemove: 'There is no landing video to remove',
                    removeError: 'There was an error while removing your landing video.',
                    removeSuccess: 'Your landing video is successfully removed.',
                    removeLandingVideoConfirmation: 'Are you sure you want to remove your landing video?',
                },
            },
            password: {
                title: 'Change my password',
                oldPassword: {
                    placeholder: 'Old password',
                    label: 'Old password',
                },
                password: {
                    placeholder: 'Password',
                    label: 'Password',
                },
                passwordConfirmation: {
                    placeholder: 'Password confirmation',
                    label: 'Password confirmation',
                },
                button: {
                    submit: {
                        label: 'Change my password',
                    },
                },
            },
        },
    },
    superAdmin: {
        title: {
            list: 'Creators',
            add: 'Add creator',
        },
        form: {
            list: {
                search: {
                    label: 'Search...',
                },
                username: {
                    label: 'Username',
                },
                fullName: {
                    label: 'Full name',
                },
                status: {
                    label: 'Status',
                },
                createdAt: {
                    label: 'Created at',
                },
                subscribers: {
                    label: 'Subscribers',
                },
                button: {
                    addCreator: {
                        label: 'Add creator',
                    },
                    edit: {
                        label: 'Edit',
                    },
                    block: {
                        label: 'Block for review',
                    },
                    active: {
                        label: 'Set to active',
                    },
                    reviewed: {
                        label: 'Set to reviewed',
                    },
                    banned: {
                        label: 'Banned the creator',
                    },
                    impersonate: {
                        label: 'Impersonate',
                    },
                },
            },
            add: {
                title: `Add a new KOL`,
                username: {
                    placeholder: 'Username',
                    label: 'Username',
                },
                slug: {
                    placeholder: 'Slug',
                    label: 'Slug',
                    formatError:
                        'Your slug needs to start with letters, can only contain lowercase letters, numbers and dashes.',
                },
                fullName: {
                    placeholder: 'Full name',
                    label: 'Full name',
                },
                email: {
                    placeholder: 'Email',
                    label: 'Email',
                },
                about: {
                    placeholder: 'About',
                    label: 'About',
                },
                bio: {
                    placeholder: 'Bio',
                    label: 'Bio',
                },
                birthdate: {
                    placeholder: 'Birth date',
                    label: 'Birth date',
                },
                gender: {
                    placeholder: 'Gender',
                    label: 'Gender',
                },
                phone: {
                    placeholder: 'Phone',
                    label: 'Phone',
                },
                website: {
                    placeholder: 'Website',
                    label: 'Website',
                },
                contentType: {
                    placeholder: 'Content type',
                    label: 'Content type',
                },
                site: {
                    placeholder: 'Select a site',
                    label: 'Site',
                },
                country: {
                    placeholder: 'Select a country',
                    label: 'Country',
                    helper: 'This is extremely important to Stripe. CANNOT BE EDITED.',
                },
                button: {
                    cancel: {
                        label: 'Cancel',
                    },
                    submit: {
                        label: 'Submit',
                    },
                },
            },
            edit: {
                search: 'Search...',
                columnMember: 'Name',
                columnCreatedAt: 'Created At',
                columnEmail: 'Email',
                editMember: 'Edit member',
                impersonate: 'Impersonate',
                success: `The kol was updated successfully!`,
            },
            globalSettings: {
                site: 'Site',
                siteHelper: 'Select a site',
                country: 'Country',
                countryPlaceholder: 'Select a country',
                countryHelper: 'This is extremely important to Stripe. CANNOT BE EDITED.',
                preferredLanguage: 'Preferred language',
                subscriptionFees: 'Subscription Fees (in %)',
                videoCallFees: 'Video Call Fees (in %)',
                featuredAt: 'Featured at',
                isFeatured: 'Featured?',
                enableNewSubscriberNotification: 'New subscriber notification',
                enableNewLikeNotification: 'New like notification',
                enableNewCommentNotification: 'New comment notification',
                enableNewTipNotification: 'New tip notification',
                enableNewMentionNotification: 'New mention notification',
                notifySubscribersNewPost: 'Notify subscribers new post',
                notifySubscribersLiveStream: 'Notify subscribers live stream',
                isCountSubscribersHidden: 'Hide subscriber count',
                isCountryHidden: 'Hide country',
                isBirthdateHidden: 'Hide birthdate',
                isFullNameHidden: 'Hide full name',
                isOnlineStatusHidden: 'Hide online status',
                enableModeration: 'Moderation',
                errors: {
                    invalidPayoutPercent: 'Please enter a valid fee percent (1 - 90)',
                },
            },
            messengerSettings: {
                disableSending: 'Disable sending',
                allowUsersToSendMedia: 'Allow users to send media',
                limitNumberOfMessagesPerMonth: 'Limit number of messages per month',
                limitNumberOfCharacters: 'Limit number of characters per messages',
                maxNumberOfMessagesPerMonth: 'Max number of messages per month',
                maxCharacterCountPerMessage: 'Max characters per message',
                invalidNumber: 'Please enter a valid number',
                success: 'Messenger settings updated successfully',
                error: 'An error occurred while updating messenger settings',
            },
        },
        message: {
            edit: {
                success: `The creator was updated successfully!`,
                error: `The creator wasn't updated, please try again!`,
            },
            add: {
                success: `The creator was created successfully!`,
                error: `The creator wasn't created, please try again!`,
            },
        },
        menu: {
            account: 'Account',
            dashboard: 'Dashboard',
            personal: 'Personal infos',
            globalSettings: 'Global settings',
            messengerSettings: 'Messenger settings',
            subscription: 'Subscription',
            plans: 'Plans',
            subscribers: 'Subscribers',
            privacySecurity: 'Privacy & Security',
            privacy: 'Privacy',
            password: 'Password',
        },
    },
    landing: {
        amazing: '{creator} is creating something amazing!',
        backSoon: 'Come back soon!',
        takeMeHome: 'Take me home',
        startExperience: 'Start Experience',
        youAreSubscribed: 'You are subscribed!',
        billed: 'Billed',
        save: 'Save',
        mostPopular: 'MOST POPULAR',
        interval: {
            monthly: 'Monthly',
            month: 'Monthly',
            quarterly: 'Quarterly',
            yearly: 'Yearly',
            year: 'Yearly',
        },
        login: 'Login',
        logout: 'Logout',
        global: {
            about: `About`,
            faqs: `FAQs`,
            gallery: {
                title: `{name}'s Gallery`,
                startExperience: `Start experience`,
            },
            selectSubscriptionPlan: `Select subscription plan`,
        },
    },
    payment: {
        defaultPerks : {
            1: "Unlock all my content",
            2: "Recurring Exclusive Content",
            3: "Access to my private messenger",
        },
        global: {
            price: 'Price',
            included: 'Included',
            plan: "{creator}'s Plan",
            landingPage: `Landing page`,
            online: `online`,
            startingSoon: `Hey! You're just 1 minute away from starting a new experience with me. Join me now!`,
            startingSoonPayment: `Hey! I'm not ready to accept subscriptions yet. Come back soon!`,
            rightTitle: `Register & Payment`,
            rightTitleStep2: `Input your card details`,
            leftTitle: 'Selected Subscription Plan',
            yourPaymentInformation: `Your payment information`,
            endingWith: `ending with`,
            secure: `Payment 100% secure`,
            logo: 'Logo Ikonstar',
            subLogo: 'Ikonstar',
            poweredBy: `Powered by`,
            success: {
                logoAlt: 'Ikonstar',
                title: 'Congratulation!',
                subTitle: 'Your payment have been successful.',
                content: '',
                goToLogin: 'Login with my account',
                goToDashboard: 'Go to my dashboard',
            },
            links: {
                privacy: 'Privacy Policy',
                terms: 'Terms of Service',
                termsUse: 'Terms of Use',
                billing: 'Billing Terms',
            },
            interval: {
                monthly: 'month',
            },
        },
        form: {
            join: 'JOIN ME NOW!',
            joinHelper: 'Welcome to{br}{creator}\'s profile!',
            tcs: 'I agree with the {0}',
            tcsRequired: 'You must accept the terms of use',
            username: {
                label: `Username`,
                placeholder: `Username`,
            },
            firstName: {
                label: `First name`,
                placeholder: `First name`,
                customError: 'First name is required field'
            },
            name: {
                label: `Full name`,
                placeholder: `Full name`,
                customError: 'Full name is required field'
            },
            lastName: {
                label: `Last name`,
                placeholder: `Last name`,
                customError: 'Last name is required field'
            },
            email: {
                label: `Email`,
                placeholder: `Email`,
            },
            country: {
                label: `Country`,
                placeholder: `Country`,
            },
            postalCode: {
                label: `Postal code`,
                placeholder: `Postal code`,
            },
            button: {
                join: 'JOIN NOW',
                submitStep1: {
                    label: `Credit & Debit Cards`,
                },
                submitStep2: {
                    label: `Validate my payment`,
                },
            },
        },
    },
    post: {
        isPinned: "Pinned Post",
        repost: "Repost",
        repostContent: "Check out this amazing post!",
        doNotClose: 'DO NOT CLOSE THIS PAGE WHEN UPLOADING MEDIA',
        previewTab: {
            all: 'ALL',
            sub: 'SUB',
            notSub: 'NOT SUB',
        },
        form: {
            add: {
                postTitle: 'New post',
                post: 'Post',
                postTeaser: 'Post teaser for non-subscribers',
                teaserMandatory: 'Mandatory with video *',
                teaserVideo: 'You can upload image or video. Videos are limited to 1 minute maximum.',
                releaseLater: 'Program your post',
                access: {
                    title: 'Who has access',
                    everyone: 'Available to everyone',
                    free: 'Available to everyone',
                    subscriber: 'Subscribers only',
                    subOtp: 'Subscribers or one-time payment',
                    otp: 'One-time payment',
                },
                postPrice: 'POST PRICE',
                enableComment: 'Enable comments?',
                disableComment: 'Disable comments',
                isPinned: 'Pin post to top ?',
                formTitle: 'Create your new post',
                titleNewEmpty: "You don't have any post yet.",
                helperNewEmpty: 'You can upload in the following way.',
                titleNewPost: 'New post',
                action1: 'Upload an Audio',
                action2: 'Upload photo/video',
                action3: 'Do a survey',
                action4: 'Do a text',
                modalTitle: {
                    post: 'Do a text',
                    audio: 'Add an Audio',
                    photo: 'Add a photo/video',
                },
                modalHelper: {
                    content: {
                        post: 'Write your text',
                        audio: 'Description',
                        photo: 'Description',
                    },
                },
                poll: {
                    toggle: 'Poll',
                    add: '+ Add an option',
                    question: 'Poll question',
                    option: 'Poll options',
                    expirationAt: 'Set poll expiration date',
                    questionError: 'Question or media is mandatory if you select a poll!',
                    answersError: "Poll answers can't be empty",
                },
                title: 'My title',
                content: 'Write something',
                price: 'Add a price?',
                file: 'Add a media',
                titleHelper: 'Title',
                priceHelper: 'Price',
                mediaHelper: 'Media',
                publish: 'Publish',
                preview: 'Preview Post',
                previewDisabled: 'Preview is disabled while videos are encoding. Please come back in a bit.',
                labelIdle: 'Click to select or Drop a file here...',
                messageFileNotAllowed: 'File type not allowed',
                success: `The post was created successfully!`,
                error: `The post wasn't created, please try again!`,
                contentRequired: 'The content',
                titleRequired: 'The title',
                priceFormat: 'The price',
                mediaRequired: 'Media is required',
                saving: 'Saving...',
                mention: 'Will create a link to this creator:',
            },
            edit: {
                postTitle: 'Edit a post',
                post: 'POST',
                postTeaser: 'POST TEASER',
                releaseLater: 'RELEASE LATER',
                media: 'Current media',
                publish: 'Publish',
                access: {
                    title: 'WHO HAS ACCESS',
                    everyone: 'Available to everyone',
                    subscriber: 'Subscribers only',
                    subOtp: 'Subscribers or one-time payment',
                    otp: 'One-time payment',
                },
                postPrice: 'POST PRICE',
                disableComment: 'DISABLE COMMENTS',
                poll: {
                    toggle: 'Poll',
                    add: '+ Add an option',
                    question: 'POLL QUESTION',
                    option: 'POLL OPTIONS',
                    expirationAt: 'EXPIRATION PERIOD',
                    questionError: 'Question or media is mandatory if you select a poll!',
                    answersError: "Poll answers can't be empty",
                },
                content: 'Write something',
                file: 'Add a media to change current',
                modalTitle: {
                    post: 'Update a text',
                    audio: 'Update an Audio',
                    photo: 'Update a photo/video',
                },
                modalHelper: {
                    content: {
                        post: 'Write your text',
                        audio: 'Description',
                        photo: 'Description',
                    },
                },
                changeMedia: 'You must first delete existing media',
                deleteMedia: {
                    title: 'Are you sure you want to delete the media?',
                    cancelDelete: 'Cancel',
                    confirmDelete: 'Yes, Do it!',
                    success: `The media was deleted successfully!`,
                    error: `The media wasn't deleted, please try again!`,
                },
                saving: 'Saving...',
                success: `The post was updated successfully!`,
                error: `The post wasn't updated, please try again!`,
            },
            update: {
                success: `The post was updated successfully!`,
            },
            comment: {
                max: 'Comment cannot be larger than {max} characters',
                add: 'Post your comment',
                edit: 'Edit your comment',
                success: 'The comment was created successfully!',
                previewMode: "You can't comment in preview mode"
            },
            title: 'I want to publish something',
            publish: 'Publish',
            videoTeaserError: 'When uploading a video post to subscribers only, a teaser image is mandatory',
            maxTeaserVideoDuration: 'Teaser videos are limited to 1 minute'
        },
        unlock: 'Unlock the content',
        online: 'Online',
        like: 'Likes',
        liked: 'Liked',
        comment: 'Comments',
        replies: {
            action: 'Reply',
            buttonAdd: 'Add a reply',
            show: 'Show Replies ({count})',
            hide: 'Hide Replies',
        },
        edit: 'Edit',
        delete: 'Delete',
        pin: 'Pin to your profile',
        unpin: 'Unpin from profile',
        titleDelete: 'Are you sure you want to delete?',
        confirmDelete: 'Delete It',
        cancelDelete: 'Cancel',
        deleteSuccess: 'Your post was deleted successfully!',
        deleteError: 'Your post was not deleted, try again!',
        today: 'Today',
        yesterday: 'Yesterday',
        unlockContent: 'Unlock the content',
        title: "{name}'s Post",
        poll: {
            expires: 'expires ',
            expired: 'expired',
        },
        readMore: {
            false: 'Read more',
            true: 'Close',
        },
        buttonCollapse: {
            more: '+ see more',
            less: '- see less',
        },
        notReleased: 'Scheduled posts',
    },
    plan: {
        plans: {
            monthly: {
                title: 'Monthly',
                interval: 'Every month',
            },
            month: {
                title: 'Monthly',
                interval: 'Every month',
            },
            quarterly: {
                title: 'Quarterly',
                interval: 'Every 3 months',
            },
            yearly: {
                title: 'Yearly',
                interval: 'Every 12 months',
            },
            year: {
                title: 'Yearly',
                interval: 'Every 12 months',
            },
        },
        form: {
            list: {
                title: 'Change my plan',
                status: {
                    title: 'Status',
                    values: {
                        enabled: 'Active',
                        disabled: 'Disabled',
                    },
                },
                button: {
                    edit: {
                        label: 'Edit plan',
                    },
                },
            },
            edit: {
                title: 'Change my plan',
                price: {
                    label: 'Price',
                    formatError: 'Price format is invalid.',
                },
                isEnabled: {
                    label: 'Is enabled',
                },
                button: {
                    submit: {
                        label: 'Save',
                    },
                },
                message: {
                    success: 'Your plan is successfully updated.',
                },
            },
        },
    },
    planPerk: {
        title: {
            edit: 'Change my plan perks',
        },
        form: {
            add: {
                title: 'Add a new plan perk',
                text: {
                    placeholder: 'Description',
                    label: 'Description',
                },
                message: {
                    success: 'Your plan perk is successfully added.',
                    error: 'Your plan perk is not successfully added.',
                },
            },
            edit: {
                title: 'Edit a plan perk',
                text: {
                    placeholder: 'Description',
                    label: 'Description',
                },
                button: {
                    submit: {
                        label: 'Save',
                    },
                    cancel: {
                        label: 'Cancel',
                    },
                    edit: {
                        label: 'Edit {lang}',
                    },
                },
                message: {
                    success: 'Your plan perk is successfully updated.',
                    error: 'Your plan perk is not successfully updated.',
                },
            },
            delete: {
                title: 'Delete a plan perk',
                button: {
                    submit: {
                        label: 'Yes, delete it',
                    },
                    cancel: {
                        label: 'Cancel',
                    },
                },
                message: {
                    success: 'Your plan perk is successfully deleted.',
                    error: 'Your plan perk is not successfully deleted.',
                },
            },
        },
    },
    privateVideoCall: {
        title: {
            list: 'Change my private video calls',
        },
        button: {
            add: 'Create New Private video call',
            cancel: 'Cancel',
        },
        form: {
            fields: {
                timeSlot: {
                    title: 'Call duration (in minutes)',
                    label: '30',
                    name: 'Call duration',
                },
                price: {
                    title: 'Price',
                    label: '99.99',
                    name: 'Price',
                },
                timeReminder: {
                    "title": "Send a reminder X minutes before",
                    "label": "10",
                    "name": "Reminder timer"
                },
                action: {
                    title: '',
                },
            },
            list: {
                timeSlot: 'Time slot',
                timeReminder: 'Reminder',
                price: 'Price',
                edit: 'Edit',
                delete: 'Delete',
            },
            add: {
                title: 'Create New Private video calls',
                submit: 'Create',
                success: 'The private video call is successfully created.',
                error: 'There was an error while creating your private video call.',
            },
            edit: {
                title: 'Edit a Private video call',
                submit: 'Save',
                success: 'The private video call is successfully edited.',
                error: 'There was an error while updating your private video call.',
            },
            delete: {
                title: 'Do you really want to delete this private video call ?',
                submit: 'Yes, Delete',
                success: 'The private video call is successfully deleted.',
                error: 'There was an error while deleting your private video call.',
            },
        },
    },
    liveStreaming: {
        start: "Start a Live Streaming",
        publish: {
            welcome: 'Hello everyone, enjoy the stream!',
        },
        helper: {
            notPublished: 'You are not yet live, you can choose your options before publishing your live!',
            comments: 'If you deactivate comments, it will not be possible to activate them during the live!',
            chat: '5min inactive will remove live and chat',
            chatBox: 'Chat will be here',
            audio: 'The creator muted his microphone, will come back soon...',
            video: 'The creator muted his camera, will come back soon...',
            visibility: {
                subscriber: 'The live will be visile only by your subscribers, you can open for all, click on',
                free: 'The live will be visible for all, you can open only for your subscribers, click on',
            },
        },
        chat: {
            helper: 'You have been muted',
            muted: 'has been muted!',
            unmuted: 'has been unmuted!',
            joined: 'has joined :)',
            left: 'has left :(',
        },
        hangUp: {
            title: 'Are you sure you want to stop the live?',
            confirm: 'Yes, do It',
            cancel: 'Cancel',
        },
        errors: {
            camera: 'Can you connect a camera to start the stream?',
            cameraConfirm: "It's done!",
            microphone: 'You need a microphone to start a stream',
            permission: 'Permission denied by system, please verify your privacy to use your camera and microphone!',
        },
    },
    payout: {
        title: 'Pay-out request',
        currentSolde: 'You current available pay-out amount is',
        yourAccountManager: 'Your account manager',
        submit: 'Request a pay-out',
        success: 'Your pay-out is successfully asked.',
        showMore: 'View all pay-out history',
        form: {
            fields: {
                file: {
                    title: 'Import your invoice',
                    label: 'Import your invoice',
                    name: 'invoice',
                    placeHolder: 'Upload your invoice',
                },
                date: {
                    title: 'Date',
                },
                amount: {
                    title: 'Amount',
                },
                invoiceNumber: {
                    title: 'Invoice number',
                },
                action: {
                    title: '',
                },
            },
        },
    },
    statistic: {
        title: 'Statistic',
        choosePeriod: 'Choose the period',
        stats: {
            amountEarned: {
                title: 'You earned',
            },
            subscribedNumber: {
                title: 'Subscribed number',
            },
        },
    },
    coverImage: {
        title: {
            edit: 'Your cover image',
        },
        form: {
            edit: {
                change: {
                    label: 'Change your cover image',
                    placeholder: 'Click to select or Drop a file here',
                },
                currentCover: {
                    label: 'Current cover',
                    error: {
                        required: 'File is required',
                    },
                },
                button: {
                    submit: {
                        label: 'Save your cover image',
                    },
                },
                message: {
                    success: 'Your cover is successfully uploaded.',
                    error: 'There was an error while uploading your cover.',
                },
            },
        },
    },
    socialNetwork: {
        title: {
            edit: 'Change my social networks',
        },
        form: {
            edit: {
                title: 'Change my social network',
                type: {
                    label: 'Social network',
                },
                username: {
                    label: 'Username',
                },
                link: {
                    label: 'Link',
                },
                button: {
                    edit: {
                        label: 'Edit social',
                    },
                    remove: {
                        label: 'Remove',
                    },
                    add: {
                        label: 'Create social network',
                    },
                    submit: {
                        label: 'Save',
                    },
                },
                message: {
                    success: 'Your social network is successfully updated.',
                },
            },
            add: {
                title: 'Create a social network',
                type: {
                    label: 'Social network',
                },
                link: {
                    label: 'Link',
                },
                username: {
                    label: 'Username',
                },
                button: {
                    submit: {
                        label: 'Create',
                    },
                },
                message: {
                    success: 'The social network is successfully created.',
                },
            },
            delete: {
                title: 'Delete a social network',
                button: {
                    submit: {
                        label: 'Delete',
                    },
                    cancel: {
                        label: 'Cancel',
                    },
                },
                message: {
                    success: 'The social network is successfully deleted.',
                },
            },
        },
    },
    module: {
        title: {
            edit: 'Manage my modules',
        },
        form: {
            edit: {
                save: 'Save',
                saving: 'Saving...',
                success: 'Modules was saved successfully!',
                error: "Modules wasn't saved successfully!",
            },
        },
    },
    messenger: {
        title: {
            edit: 'Manage my messenger',
        },
        form: {
            edit: {
                save: 'Save',
            },
        },
    },
    availability: {
        titleTimezone: 'Your timezone',
        title: 'Your availability',
        helper: 'Set the hours you are available',
        notAvailable: "I'm not available",
        button: 'Save my availability',
        success: 'Your availability was saved successfully!',
        error: "Your availability wasn't saved successfully, try again later!",
        days: {
            mon: 'Mon',
            tue: 'Tue',
            wed: 'Wed',
            thu: 'Thu',
            fri: 'Fri',
            sat: 'Sat',
            sun: 'Sun',
        },
        delete: {
            title: 'Are you sure you wan to delete?',
            confirm: 'Yes, do it!',
            cancel: 'cancel',
        },
    },
    onboarding: {
        title: 'Set up your IKONSTAR account',
        welcome: 'Welcome {name}',
        thankYou: 'Thank you for joining {creatorName}',
        letsSetupYourAccount: 'Let’s setup your account',
        form: {
            step1: {
                title: 'Tell us more about yourself',
                firstname: 'Firstname',
                lastname: 'Lastname',
                birthdate: 'Your birthdate',
                gender: 'Your gender',
            },
            step2: {
                title: 'Please select your modules',
                helper: {
                    workouts: 'Targeted exercise session and training program.',
                    gallery: 'Sharing photos among your members and yourself.',
                    recipes: 'Complete Workout Recipe Set',
                    polls: 'Include polls in your posts for your members.',
                    'private-video-calls': 'Private live calls to your members.',
                    masterclass: 'Lessons modules to share your skills and knowledge',
                    podcasts: 'Informative audio/video series covering diverse topics',
                },
            },
            step3: {
                title: "Let's get down to business!",
                username: 'Username',
                slug: 'Slug',
                postalCode: 'Postal code',
                country: 'Country',
                timezone: 'Timezone',
                socials: 'Social Networks',
            },
            step4: {
                title: 'Select or customize your subscription plan',
                monthly: 'monthly',
                currency: 'Choose a default currency',
                custom: 'Other monthly price',
            },
        },
        skip: 'Skip',
        next: 'Next',
        numberTypeError: 'Please enter a valid number',
        invalidFirstname: 'Please select a valid firstname',
        invalidLastname: 'Please select a valid lastname',
        invalidGender: 'Please select a valid value',
        requiredGender: 'Please select your gender field',
        invalidUsername: 'Please enter a valid username',
        invalidSlug: 'Please enter a valid slug',
        invalidCountry: 'Please select a valid value',
        requiredCountry: 'Please select your country field',
        invalidTimezone: 'Please select a valid value',
        requiredTimezone: 'Please select your timezone field',
        invalidBirthdate: 'Please select a valid birthdate',
        requiredCurrency: 'Please select a currency',
        invalidCurrency: 'Please select a currency',
        requiredSocial: 'Social Network is required',
        requiredSocialName: 'Username is required',
        invalidPrice: 'Please enter a price equal or greater than 4.99',
        success: 'Your onboarding information has been recorded successfully',
    },
    faq: {
        title: {
            edit: 'My FAQs',
        },
        form: {
            add: {
                title: 'Create new question (English)',
                question: {
                    label: 'Question',
                },
                answer: {
                    label: 'Answer',
                },
                button: {
                    add: {
                        label: 'Create new question',
                    },
                    submit: {
                        label: 'Create',
                    },
                },
                message: {
                    success: 'The question is successfully created.',
                },
            },
            edit: {
                title: 'Edit a question ({lang})',
                question: {
                    label: 'Question',
                },
                answer: {
                    label: 'Answer',
                },
                button: {
                    submit: {
                        label: 'Save',
                    },
                    cancel: {
                        label: 'Cancel',
                    },
                },
                message: {
                    success: 'The question is successfully edited.',
                },
            },
            delete: {
                title: 'Do you really want to delete this question?',
                button: {
                    submit: {
                        label: 'Yes, Delete',
                    },
                    cancel: {
                        label: 'Cancel',
                    },
                },
                message: {
                    success: 'The question is successfully deleted.',
                },
            },
        },
    },
    stripeConnect: {
        title: 'Hey {name}, your Stripe Partner Account is now setup!',
        subtitle: '',
        next: 'Users can now subscribe to your profile!',
        button: 'Back to Feed',
    },
    stripeIdVerification: {
        error: 'Looks like something went wrong. Please try again.',
        form: {
            title: "Hey {name}, let's get your account verified!",
            step1: 'Specify the desired account configuration',
            step2: 'Validate your identification',
            step3: 'Validate your address',
            button: {
                next: 'Next',
                previous: 'Previous',
                verifyMe: 'VERIFY ME',
                submit: 'Submit',
            },
            accountType: {
                title: 'Specify the desired account configuration.',
                personal: {
                    title: 'Personal Account',
                    description: "Verify as a 'Personal Account' if you do not yet have a company.",
                },
                professional: {
                    title: 'Professional Account',
                    description: 'Choose this option if you have a company.',
                    subtitle: 'Tell us more about your company',
                },
            },
            verify: {
                title: 'Verify your identification',
            },
            inputs: {
                required: '{field} is required',
                company: 'Company name',
                company_number: 'Company Number',
                company_vat: 'Company VAT number',
                address_1: 'Company Address',
                address_2: 'Company Address (more)',
                postcode: 'Company Postcode',
                city: 'Company City',
                country_id: 'Company Country',
                country: 'Select a country',
            },
        },
        titles: {
            created: 'Verify your identity so that your profile can be accessible.',
            processing: 'Your identity is being verified',
            verified: 'Your identity is now verified',
            requires_input: 'Verify your identity so that your profile can be accessible.',
            canceled: 'Verify your identity so that your profile can be accessible.',
            redacted: 'Verify your identity so that your profile can be accessible.',
        },
        errors: {
            consent_declined: 'The user declined to be verified by Stripe. Your account cannot be verified without it.',
            under_supported_age: 'Stripe does not verify users under the age of majority.',
            country_not_supported: 'Stripe does not verify users from the provided country.',
            document_expired: 'The provided identity document has expired.',
            document_unverified_other: 'Stripe could not verify the provided identity document.',
            document_type_not_supported: 'The provided identity document isn’t one of the session’s allowed document types.',
            selfie_document_missing_photo: 'The provided identity document did not contain a picture of a face.',
            selfie_face_mismatch: 'The captured face image did not match with the document’s face.',
            selfie_unverified_other: 'Stripe could not verify the provided selfie.',
            selfie_manipulated: 'The captured face image was manipulated.',
            id_number_unverified_other: 'Stripe could not verify the provided ID number. See list of supported ID numbers.',
            id_number_insufficient_document_data: 'The provided document did not contain enough data to match against the ID number.',
            id_number_mismatch: 'The information provided could not be matched against global databases.',
        },
        review: {
            title: 'Hey {name}, your identity verification is now under review',
            subtitle: 'We will let you know of the outcome',
            next: 'In the meantime, you can continue the tutorial!',
            button: 'Back to tutorial',
        },
    },
    tutorial: {
        title: 'Tutorial Progress',
        complete: 'You completed the mandatory tutorial!',
        stripeIdUnlock: 'ID Verification pending, please come back later',
        stripeUnlock: 'Fulfill all the other tasks to unlock this one',
        stripeCountryInfo: {
            title: "Please read carefully",
            description: "This form is one the most important in setting up you account. The values selected on this step will be used to create you Stripe Partner Account on Ikonstar. Those values cannot be edited later. Double check each and every input."
        },
        steps: {
            title: 'Step {nb}',
            2: 'Account verification',
            1: 'Complete your profile',
            3: 'Going live!',
            optional: 'Optional settings',
        },
        avatar: {
            title: "Let's get you started!",
            content: "Click here to change your avatar!",
        },
        todos: {
            billing_verified: 'Verify your account information',
            id_verified: 'Verify your identity',
            avatar: 'Upload an avatar',
            header: 'Upload a header image',
            about: 'Fill your About Me section',
            bio: 'Fill your My Bio section',
            socials: 'Add your social networks to your profile',
            sub_perks: 'Complete your subscriptions and perks',
            five_posts: 'Create at least 3 posts',
            stripe_connect: 'Create your Ikonstar Partner Stripe account',
            modules: 'Toggle modules for your platform',
            private_video_calls: 'Setup some Private Video Call pricings',
            messenger_settings: 'Choose your Messenger settings',
        },
    },
    podcasts: {
        title: 'Podcasts',
        nowPlaying: 'PLAYING NOW',
        index: {
            empty: 'Looks like your first visit here!',
            createFirst: 'Setup your Podcasts page',
            create: 'Create new podcast',
        },
        setup: {
            title: 'Setup my Podcasts page',
            editButton: 'Edit page settings',
            formTitle: 'Page title',
            formDescription: 'Page description',
            submit: 'Save',
            notification: {
                add: {
                    success: 'Your podcast page was setup successfully!',
                    error: 'Your podcast page was not setup, please try again!',
                },
                update: {
                    success: 'Your podcast page was updated successfully!',
                    error: 'Your podcast page was not updated, please try again!',
                },
            },
        },
        episode: {
            createTitle: 'Create a new Podcast',
            editTitle: 'Edit Podcast',
            saveTitle: 'Save Podcast',
            fieldset: 'Your Podcast Info',
            visibility: 'Who can play this Podcast?',
            title: 'Podcast title',
            description: 'Podcast short description',
            type: 'Type of Podcast',
            video: "Podcast's video",
            audio: "Podcast's audio",
            reorder: 'Re-order Podcasts',
            applyReorder: 'Apply new order',
            cancelReorder: 'Cancel',
            notification: {
                add: {
                    success: 'Your podcast was created successfully!',
                    error: 'Your podcast was not created, please try again!',
                },
                update: {
                    success: 'Your podcast was updated successfully!',
                    error: 'Your podcast was not updated, please try again!',
                },
                delete: {
                    success: 'Your podcast was deleted successfully!',
                    error: 'Your podcast was not deleted, please try again!',
                },
                order: {
                    success: 'Your podcasts were reordered successfully!',
                    error: 'Your podcast were not reordered, please try again!',
                },
            },
        },
    },
    series: {
        episode: {
            reorder: 'Re-order exercises',
            applyReorder: 'Apply new order',
            cancelReorder: 'Cancel',
            notification: {
                add: {
                    success: 'Your episode was created successfully!',
                    error: 'Your episode was not created, please try again!',
                },
                update: {
                    success: 'Your episode was updated successfully!',
                    error: 'Your episode was not updated, please try again!',
                },
                delete: {
                    success: 'Your episode was deleted successfully!',
                    error: 'Your episode was not deleted, please try again!',
                },
                order: {
                    success: 'Your episodes were reordered successfully!',
                    error: 'Your episodes were not reordered, please try again!',
                },
            },
        },
    },
    privacy: {
        title: {
            edit: 'My Privacy',
        },
        form: {
            edit: {
                visibility: 'Hide my profile in the search engine',
                success: 'Your settings was updated successfully!',
                error: "Your settings wasn't updated successfully!",
                save: 'Save',
            },
        },
    },
}
export default creator
