const page = {
    "global": {
        "title": "Mi Página"
    },
    "user": {},
    "superAdmin": {},
    "creator": {
        "subscribersCount": "Suscriptores",
        registersCount: 'Inscritos',
        followersCount: 'Followers',
        "latestWorkout": "Último Entrenamiento",
        "myWorkouts": "Ver mis Entrenamientos",
        "addWorkout": "Crear un Entrenamiento",
        "latestMasterclass": "Última Masterclass",
        "myMasterclass": "Ver mis Masterclass",
        "addMasterclass": "Crear una Masterclass"
    },
    "guest": {},
    "form": {}
}


export default page
