const user = {
    "menu": {
        "hi": "Hola,",
        "signOutKolSelect": "No soy yo, cerrar sesión",
        "dashboard": "Tablero",
        home: 'Inicio',
        "feed": "Feed",
        "messages": "Mensajes",
        "masterclasses": "Masterclasses",
        "workouts": "Entrenamientos",
        "myCalendar": "Mi Calendario",
        "post": "Publicar",
        "nutritionGuides": "Guías de Nutrición",
        "myPlan": "Mi Plan",
        "myProgress": "Mi Progreso",
        "photoGallery": "Galería de Fotos",
        "myFavorites": "Mis Favoritos",
        "myGallery": "Mi Galería",
        "recipes": "Recetas",
        "viewAccount": "Ver Cuenta",
        "changeIkon": "Cambiar mi Ikonstar",
        "selectAnIkon": "¡Cambia de Ikon en unos pocos clics!",
        "followedIkon": "Actualmente sigues a",
        "myAccount": "Mi Cuenta",
        "signOut": "Cerrar sesión",
        "creator": {
            "select": "Selecciona tu Ikonstar",
            "viewingNow": "Viendo ahora",
            "changeSuccess": "Ahora estás viendo a {name}"
        }
    },
    "user": {
        "account": {
            "title": "Tus ajustes de perfil",
            menu: {
                account: 'Cuenta',
                personal: 'Información Personal',
                password: 'Contraseña',
                subscription: 'Suscripciones',
                settings: 'Mis ajustes',
                language: 'Idioma',
                notification: 'Notificación',
                privacy: "Confidencialidad",
            }
        },
        "form": {
            notificationManager: {
                title: 'Gestor de notificaciones',
                allowNotification: 'Activar notificaciones',
                selectAll: 'Seleccionar/Deseleccionar todo',
                event: 'Evento',
                submit: 'Guardar mis preferencias',
                success: 'Tus preferencias de notificación se han actualizado correctamente.',
                enabled: 'notificaciones para {creator} son activadas',
                disabled: 'notificaciones para {creator} son desactivadas',
                error: 'Se produjo un error al actualizar tus preferencias de notificación.',
            },
            "language": {
                "title": "Tu idioma preferido",
                "field": "Idioma",
                "submit": "Cambiar idioma"
            },
            "personalInfo": {
                "title": "Tu información personal",
                "changeEmail": {
                    linkHelper: 'Puedes cambiar tu correo electrónico',
                    link: 'aquí',
                    confirm: {
                        title: '¿Estás seguro/a de que deseas cambiar tu correo electrónico?',
                        submit: 'Sí, hazlo.',
                        cancel: 'Cancelar',
                    },
                    "title": {
                        "step1": "¿Cuál es la nueva dirección de correo electrónico?",
                        "step2": "Por favor, verifica tu correo electrónico",
                        "step3": "Se ha establecido el nuevo correo electrónico"
                    },
                    "helper": {
                        "step1": "Enviaremos un código de verificación a tu nueva dirección de correo electrónico",
                        "step2": "Hemos enviado un código a",
                        "step3": "Se cerrará tu sesión para aplicar los cambios."
                    },
                    "sendCode": "Enviar código",
                    "verify": "Verificar",
                    "great": "Genial"
                },
                "fields": {
                    "username": {
                        "label": "Nombre de usuario",
                        "name": "nombre de usuario"
                    },
                    "name": {
                        "label": "Nombre completo",
                        "name": "nombre"
                    },
                    "email": {
                        "label": "Correo electrónico",
                        "name": "correo electrónico"
                    },
                    "code": {
                        "label": "Código"
                    },
                    "slug": {
                        "label": "Slug",
                        "name": "slug",
                        "validations": {
                            "format": "Tu slug debe comenzar con letras y solo puede contener letras minúsculas, números y guiones."
                        }
                    },
                    "gender": {
                        "label": "Género",
                        "name": "género",
                        "validations": {
                            "match": "El género seleccionado no es válido."
                        }
                    },
                    "birthdate": {
                        "label": "Fecha de nacimiento",
                        "name": "fecha de nacimiento",
                        "validations": {
                            "invalid": "La fecha de nacimiento no es válida."
                        }
                    },
                    "website": {
                        "label": "Sitio web",
                        "name": "sitio web"
                    },
                    "phone": {
                        "label": "Teléfono",
                        "name": "teléfono"
                    },
                    "about": {
                        "label": "Acerca de",
                        "name": "acerca de"
                    },
                    "bio": {
                        "label": "Biografía",
                        "name": "biografía"
                    },
                    "old_password": {
                        "label": "Contraseña anterior",
                        "name": "contraseña anterior"
                    },
                    "password": {
                        "label": "Contraseña",
                        "name": "contraseña"
                    },
                    "password_confirmation": {
                        "label": "Confirmación de contraseña",
                        "name": "confirmación de contraseña",
                        "validations": {
                            "match": "Las contraseñas deben coincidir"
                        }
                    }
                },
                "submit": "Guardar mi información personal",
                "success": "Tu información personal se ha actualizado correctamente.",
                "successPassword": "Tu contraseña se ha actualizado correctamente.",
                "error": "Hubo un error al actualizar tu información personal."
            },
            "subscription": {
                endingWith: "terminando",
                "title": "Resumen de suscripción",
                "paymentMethod": "Método de pago",
                "subscriptionPrice": "Precio de la suscripción",
                isCanceled: 'Suscripción cancelada',
                "nextExpiryDate": "Próxima renovación de la suscripción",
                "subscriptionType": "Tipo de suscripción",
                "graceUntil": "Período de gracia",
                "save": "Guardar",
                "resumeButton": "Reanudar mi suscripción",
                "titleResume": "¿Estás seguro de que quieres reanudar?",
                "confirmResume": "Reanudar",
                "resumeSuccess": "Tu suscripción se reanudó con éxito.",
                "resumeError": "Tu suscripción no se reanudó, inténtalo de nuevo.",
                "cancelButton": "Cancelar mi suscripción",
                "titleCancel": "¿Estás seguro de que quieres cancelar su suscription a {creator}?",
                "confirmCancel": "Sí, cancelar",
                "closeButton": "No, no voy a cancelar",
                "cancelSuccess": "Tu suscripción se ha cancelado con éxito.",
                "cancelError": "Tu suscripción no se ha cancelado, inténtalo de nuevo."
            }
        }
    },
    "superAdmin": {
        "menu": {
            "account": "Cuenta",
            "personal": "Información personal",
            "management": "Gestión",
            "members": "Miembros",
            "creators": "Creadores",
            "security": "Seguridad"
        },
        "title": {
            "list": "Miembros",
            "add": "Agregar un nuevo miembro",
            "edit": "Editar un miembro",
            "ban": "Prohibir usuario",
            "accountManager": "Administrador de cuenta"
        },
        "form": {
            "list": {
                "search": "Buscar...",
                "button": {
                    "add": "Agregar miembro"
                },
                "filters": {
                    "onlyBanned": "Solo prohibidos",
                    "onlySubscribers": "Solo suscriptores"
                },
                "columnMember": "Nombre",
                "columnRole": "Rol",
                "columnStatus": "Estado",
                "columnCreatedAt": "Creado en",
                "columnEmail": "Correo electrónico",
                "convertToCreator": "Convertir a Creador",
                "editMember": "Editar miembro",
                "banMember": "Prohibir miembro",
                "unbanMember": "Desprohibir miembro",
                "impersonate": "Suplantar",
                "confirmationBan": "¿Estás seguro de que quieres prohibir?",
                "confirmationUnban": "¿Estás seguro de que quieres desprohibir?",
                "banIt": "¡Sí, prohibirlo!",
                "unbanIt": "¡Sí, desprohibirlo!",
                "no": "No",
                "activateSuccess": "¡Esta acción se realizó con éxito!",
                "activateError": "¡Esta acción no se realizó con éxito!",
                "confirmationConvertToCreator": "¿Estás seguro de que quieres convertir a este usuario en un Creador? Esta acción no se puede deshacer.",
                "convertToCreatorSuccess": "El usuario se ha convertido en Creador con éxito!"
            },
            "add": {
                "name": "Nombre completo",
                "username": "Nombre de usuario",
                "email": "Dirección de correo electrónico",
                "birthdate": "Fecha de nacimiento",
                "role": "Rol",
                "password": "Contraseña",
                "matchPassword": "Confirmar contraseña",
                "verified": "Verificado",
                "job": "Rol laboral",
                "cancel": "Cancelar",
                "submit": "Enviar",
                "back": "volver",
                "success": "¡El miembro se creó con éxito!",
                "error": "El miembro no se creó, inténtalo de nuevo."
            },
            "edit": {
                "name": "Nombre completo",
                firstname: `Nombre de pila`,
                lastname: `Apellido`,
                language: `Idioma`,
                "username": "Nombre de usuario",
                "email": "Dirección de correo electrónico",
                "birthdate": "Fecha de nacimiento",
                "role": "Rol",
                "password": "Contraseña",
                "matchPassword": "Confirmar contraseña",
                "verified": "Verificado",
                "job": "Rol laboral",
                "cancel": "Cancelar",
                "submit": "Enviar",
                "back": "volver",
                "success": "¡El miembro se actualizó con éxito!",
                "error": "El miembro no se actualizó, inténtalo de nuevo."
            },
            "ban": {
                "banReason": "Motivo de prohibición",
                "placeholder": "Ingrese el motivo de la prohibición"
            },
            "accountManager": {
                "attachKol": "Adjuntar un Creador",
                "detachKol": "Desanclar creador",
                "search": "¿A quién estás buscando?",
                "columnKol": "Nombre de usuario",
                "columnName": "Nombre completo",
                "columnCreatedAt": "Creado en",
                "columnFollowers": "Suscriptores",
                "modal": {
                    "attach": {
                        "title": "Seleccionar un Creador",
                        "submit": "Guardar",
                        "creator": {
                            "name": "Creador",
                            "label": "Creador"
                        },
                        "success": "¡El Creador se adjuntó con éxito!",
                        "error": "El Creador no se adjuntó, inténtalo de nuevo."
                    },
                    "detach": {
                        "title": "Seleccionar un Creador",
                        "submit": "Guardar",
                        "creator": {
                            "name": "Creador",
                            "label": "Creador"
                        },
                        "confirm": "¿Estás seguro de que quieres desanclar a este Creador?",
                        "success": "¡El Creador se desancló con éxito!",
                        "error": "El Creador no se desancló, inténtalo de nuevo."
                    }
                }
            }
        }
    },
    "myWorkoutProgram": {
        "title": "Programa de entrenamiento",
        "searchPlaceholder": "Buscar un programa de entrenamiento",
        "empty": "No hay programas de entrenamiento",
        "joinSuccess": "Programa de entrenamiento unido con éxito",
        "week": "semana",
        "weeks": "semanas",
        "day": "día",
        "days": "días",
        "features": "Características del programa",
        "recipeCategory": "Categoría de receta",
        "listContent": "Lista de contenido",
        "takeBreak": "Tomar un descanso",
        "followersCompleted": "seguidores completaron",
        "filter": {
            "bodyPart": "Parte del cuerpo",
            "level": "Nivel",
            "category": "Categoría"
        },
        "buttons": {
            "joinProgram": "Unirse al programa",
            "joinedProgram": "Programa unido"
        },
        "message": {
            "joinSuccess": "Unirse al programa con éxito",
            "joinError": "Unirse al programa sin éxito"
        },
        "form": {
            "title": "Unirse a este programa",
            "fields": {
                "date": "Comenzando a las",
                "hour": "Seleccionar hora"
            }
        }
    },
    "onboarding": {
        "title": "Configura tu cuenta de IKONSTAR",
        "welcome": "Bienvenido {name}",
        "thankYou": "Gracias por unirte a {creatorName}",
        "letsSetupYourAccount": "Vamos a configurar tu cuenta",
        "form": {
            "step1": {
                "title": "Por favor, ingresa tus medidas corporales",
                "yourAge": "Tu edad",
                "yourBirthdate": "Tu fecha de nacimiento",
                "years": "años",
                "yourHeight": "Tu altura",
                "yourWeight": "Tu peso",
                "yourGender": "Tu género",
                "weightUnit": "Unidad de peso",
                "heightUnit": "Unidad de altura"
            },
            "step2": {
                "title": "Por favor, responde las siguientes preguntas",
                "chooseYourObjective": "Elige tu objetivo",
                "objectives": {
                    "toMaintainAHealthyBody": "Mantener un cuerpo saludable",
                    "toBeFitOrLoseWeight": "Estar en forma / perder peso",
                    "toGainMuscleAndCurves": "Ganar músculo y curvas",
                    "toDevelopStrength": "Desarrollar fuerza"
                }
            },
            "step3": {
                "title": "Por favor, responde las siguientes preguntas",
                "yourActivityLevel": "Tu nivel de actividad",
                "activityLevels": {
                    "noExercise": "Sin ejercicio",
                    "light": "Ligero (1 día a la semana)",
                    "moderate": "Moderado (2-3 días a la semana)",
                    "intense": "Intenso (más de 4 días)"
                }
            }
        },
        "skip": "Saltar",
        "next": "Siguiente",
        "numberTypeError": "Por favor, introduce un número válido",
        "invalidGender": "Por favor, selecciona un valor válido",
        "requiredGender": "Por favor, selecciona tu género",
        "invalidHeightUnit": "Por favor, selecciona una unidad de altura",
        "invalidWeightUnit": "Por favor, selecciona una unidad de peso",
        "invalidObjective": "Por favor, selecciona al menos un objetivo",
        "invalidActivityLevel": "Por favor, selecciona un nivel de actividad válido",
        "invalidBirthdate": "Por favor, selecciona una fecha de nacimiento válida",
        "success": "La información de tu configuración se ha registrado con éxito"
    },
    "workouts": {
        "label": "Entrenamientos",
        "title": "Mis Entrenamientos",
        "subTitle": "Hoy eres quien brilla",
        "emptyFilter": {
            "workouts": "No hay entrenamientos con estos filtros",
            "masterclass": "No hay entrenamientos con estos filtros",
            "video": "No hay videos con estos filtros"
        },
        "empty": {
            "workouts": "no ha publicado ningún entrenamiento todavía",
            "masterclass": "no ha publicado ningún entrenamiento todavía",
            "video": "no ha publicado ningún video todavía"
        },
        "subMenu": {
            "allClasses": "Entrenamientos",
            "allVideos": "Videos",
            "workoutProgram": "Programas"
        },
        "filter": {
            "bodyPart": "Todas las partes del cuerpo",
            "location": "Ubicación",
            "duration": "Duración"
        },
        "sort": {
            "label": "Ordenar por",
            "latestJoined": "Más recientes",
            "oldestJoined": "Más antiguos"
        },
        "search": {
            "placeholder": "Buscar un entrenamiento"
        },
        "likes": "me gusta",
        "episodes": {
            "label": "Ejercicio",
            "viewFaq": "Ver Preguntas Frecuentes",
            "viewTips": "Ver Consejos",
            "tips": {
                "title": "¿Tienes una pregunta?",
                "label": "Consejo",
                "done": "Hecho"
            },
            "repsIndication": "Sets: {current} / {total}"
        },
        "modal": {
            "confirmExit": "¿Estás seguro de que deseas cerrar el ejercicio?",
            "continue": "Continuar ejercicio",
            "exit": "Salir del ejercicio"
        },
        "viewEpisode": {
            "enterYourSet": "Número de sets",
            "times": "set",
            "autoPlay": "Reproducción automática",
            "autoPlayTips": "Continuar automáticamente con el siguiente episodio después del descanso",
            "startNow": "Comenzar ahora",
            "ready": "Listo",
            "takeABreak": "Tomar un descanso",
            "nextEpisodeIn": "Siguiente episodio en",
            "nextRepIn": "Siguiente set en",
            "requiredEquipments": "Equipos necesarios",
            "repetitions": "Repeticiones"
        },
        "programs": {
            "label": "Programas"
        }
    },
    live: {
        waiting: 'Por favor, espera, la transmisión en vivo comenzará pronto.',
        seeLast: 'Ver los últimos mensajes',
        finished: 'La transmisión en vivo ha terminado, nos vemos en la próxima!',
        backToFeed: 'volver al feed',
        notStarted: 'EL EN VIVO AÚN NO HA COMENZADO',
        chatRateLimit: 'Estás enviando mensajes demasiado rápido.',
        mutedTitle: 'EXPULSIÓN TEMPORAL',
        mutedHelper: 'Te han expulsado temporalmente del chat.',
        mutedMessage: 'Mensaje eliminado por un moderador.',
    }
}

export default user
