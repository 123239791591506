const creator = {
    search: 'Rechercher',
    searchHelper: 'Tapez quelque chose pour rechercher',
    menu: {
        dashboard: 'Tableau de bord',
        openPage: 'Ouvrir ma page',
        myPage: 'Ma page',
        mySettings: 'Mes paramètres',
        myWorkouts: 'Entraînements',
        myWorkoutPrograms: 'Programmes',
        masterclasses: 'Masterclass',
        podcasts: 'Podcasts',
        liveEvents: 'Évènements',
        myPlans: 'Tarifs des plans',
        messages: 'Messagerie',
        medias: 'Médias',
        gallery: 'Galerie',
        recipes: 'Recettes',
        creators: 'Mes créateurs',
        subscribers: 'Mes abonnés',
        followers: 'Mes followers',
        accountManager: {
            management: "Management",
            posts: "Posts",
        }
    },
    creator: {
        title: {
            settings: 'Vos paramètres de profil',
        },
        form: {
            menu: {
                language: 'Langue préférée',
                personal: 'Infos personnel',
                cover: 'Cover image',
                landing: 'Landing video',
                password: 'Mot de passe',
                social: 'Réseaux sociaux',
                module: 'Modules',
                messenger: 'Messagerie',
                availability: 'Disponibilités',
                plan: 'Mon abonnement',
                perk: 'Mes avantages',
                video: 'Appels privés',
                faq: 'Ma Faqs',
            },
            avatar: {
                edit: "Modifier l'avatar",
                description: "Tailles de l'avatar en pixels : minimum 200x200 - maximum 1080x1080",
                message: {
                    success: 'Votre avatar a été téléchargé avec succès.',
                },
            },
            headerImg: {
                edit: 'Modifier la bannière',
                message: {
                    success: "Votre image d'en-tête a été téléchargée avec succès.",
                    removeConfirmation: "Êtes-vous sûr de vouloir supprimer votre image d'en-tête ?",
                    removeSuccess: "Votre image d'en-tête a été supprimée avec succès.",
                    removeError: "Une erreur s'est produite lors de la suppression de votre image d'en-tête.",
                },
                button: {
                    remove: 'Supprimer',
                },
            },
            personalInfo: {
                title: 'Vos informations personnelles',
                contactUsIfYouSpotAMistake: 'Contactez-nous si vous repérez une erreur',
                username: {
                    placeholder: "Nom d'utilisateur",
                    label: "Nom d'utilisateur",
                },
                slug: {
                    placeholder: 'Slug',
                    label: 'Slug',
                    formatError:
                        'Votre slug doit commencer par des lettres, ne peut contenir que des lettres minuscules, des chiffres et des tirets.',
                },
                fullName: {
                    placeholder: 'Nom complet',
                    label: 'Nom complet',
                },
                email: {
                    placeholder: 'Email',
                    label: 'Email',
                },
                about: {
                    placeholder: 'À propos',
                    label: 'À propos',
                },
                bio: {
                    placeholder: 'Bio',
                    label: 'Bio',
                },
                birthdate: {
                    placeholder: 'Date de naissance',
                    label: 'Date de naissance',
                },
                gender: {
                    placeholder: 'Genre',
                    label: 'Genre',
                },
                phone: {
                    placeholder: 'Téléphone',
                    label: 'Téléphone',
                },
                website: {
                    placeholder: 'Site web',
                    label: 'Site web',
                },
                button: {
                    submit: {
                        label: 'Enregistrer les modifications',
                    },
                },
                message: {
                    success: 'Vos informations personnelles ont été mises à jour avec succès.',
                },
            },
            landingVideo: {
                title: 'Votre vidéo de page de destination',
                uploadVideo: {
                    label: 'Télécharger une vidéo',
                },
                currentVideo: {
                    label: 'Vidéo actuelle',
                },
                remove: {
                    label: 'Supprimer la vidéo de page de destination',
                },
                button: {
                    submit: {
                        label: 'Télécharger votre vidéo de page de destination',
                    },
                    removeConfirmation: {
                        label: 'Supprimer',
                    },
                },
                message: {
                    noFileSelected: 'Veuillez sélectionner au moins un fichier vidéo',
                    error: "Une erreur s'est produite lors du téléchargement de votre vidéo",
                    success: 'Votre vidéo a été téléchargée avec succès et est en cours de traitement.',
                    noVideoToRemove: "Il n'y a pas de vidéo de page de destination à supprimer",
                    removeError: "Une erreur s'est produite lors de la suppression de votre vidéo de page de destination.",
                    removeSuccess: 'Votre vidéo de page de destination a été supprimée avec succès.',
                    removeLandingVideoConfirmation: 'Êtes-vous sûr de vouloir supprimer votre vidéo de page de destination ?',
                },
            },
            password: {
                title: 'Changer mon mot de passe',
                oldPassword: {
                    placeholder: 'Ancien mot de passe',
                    label: 'Ancien mot de passe',
                },
                password: {
                    placeholder: 'Mot de passe',
                    label: 'Mot de passe',
                },
                passwordConfirmation: {
                    placeholder: 'Confirmation du mot de passe',
                    label: 'Confirmation du mot de passe',
                },
                button: {
                    submit: {
                        label: 'Changer mon mot de passe',
                    },
                },
            },
        },
    },
    superAdmin: {
        title: {
            list: 'Créateurs',
            add: 'Ajouter un créateur',
        },
        form: {
            list: {
                search: {
                    label: 'Rechercher...',
                },
                username: {
                    label: "Nom d'utilisateur",
                },
                fullName: {
                    label: 'Nom complet',
                },
                status: {
                    label: 'Statut',
                },
                createdAt: {
                    label: 'Créé le',
                },
                subscribers: {
                    label: 'Abonnés',
                },
                button: {
                    addCreator: {
                        label: 'Ajouter un créateur',
                    },
                    edit: {
                        label: 'Modifier',
                    },
                    block: {
                        label: 'Mettre en review',
                    },
                    active: {
                        label: 'Activer le compte',
                    },
                    reviewed: {
                        label: 'Compte vérifié',
                    },
                    banned: {
                        label: 'Bannir le createur',
                    },
                    impersonate: {
                        label: 'Impersonate',
                    },
                },
            },
            add: {
                title: 'Ajouter un nouveau KOL',
                username: {
                    placeholder: "Nom d'utilisateur",
                    label: "Nom d'utilisateur",
                },
                slug: {
                    placeholder: 'Slug',
                    label: 'Slug',
                    formatError:
                        'Votre slug doit commencer par des lettres, ne peut contenir que des lettres minuscules, des chiffres et des tirets.',
                },
                fullName: {
                    placeholder: 'Nom complet',
                    label: 'Nom complet',
                },
                email: {
                    placeholder: 'Email',
                    label: 'Email',
                },
                about: {
                    placeholder: 'À propos',
                    label: 'À propos',
                },
                bio: {
                    placeholder: 'Bio',
                    label: 'Bio',
                },
                birthdate: {
                    placeholder: 'Date de naissance',
                    label: 'Date de naissance',
                },
                gender: {
                    placeholder: 'Genre',
                    label: 'Genre',
                },
                phone: {
                    placeholder: 'Téléphone',
                    label: 'Téléphone',
                },
                website: {
                    placeholder: 'Site web',
                    label: 'Site web',
                },
                contentType: {
                    placeholder: 'Type de contenu',
                    label: 'Type de contenu',
                },
                site: {
                    placeholder: 'Sélectionnez un site',
                    label: 'Site',
                },
                country: {
                    placeholder: 'Sélectionnez un pays',
                    label: 'Pays',
                    helper: 'Ceci est extrêmement important pour Stripe. NE PEUT PAS ÊTRE MODIFIÉ.',
                },
                button: {
                    cancel: {
                        label: 'Annuler',
                    },
                    submit: {
                        label: 'Soumettre',
                    },
                },
            },
            edit: {
                search: 'Rechercher...',
                columnMember: 'Nom',
                columnCreatedAt: 'Créé le',
                columnEmail: 'Email',
                editMember: 'Modifier le membre',
                impersonate: 'Impersonate',
                success: 'Le KOL a été mis à jour avec succès !',
            },
            globalSettings: {
                site: 'Site',
                siteHelper: 'Sélectionnez un site',
                country: 'Pays',
                countryPlaceholder: 'Sélectionnez un pays',
                countryHelper: 'Ceci est extrêmement important pour Stripe. NE PEUT PAS ÊTRE MODIFIÉ.',
                preferredLanguage: 'Langue préférée',
                subscriptionFees: "Frais d'abonnement (en %)",
                videoCallFees: 'Frais de visioconférence (en %)',
                featuredAt: 'Mise en avant',
                isFeatured: 'En vedette ?',
                enableNewSubscriberNotification: 'Notification nouveau abonné',
                enableNewLikeNotification: 'Notification nouveau like',
                enableNewCommentNotification: 'Notification nouveau commentaire',
                enableNewTipNotification: 'Notification nouveau pourboire',
                enableNewMentionNotification: 'Notification nouveau mention',
                notifySubscribersNewPost: 'Notifier les abonnés de nouveaux messages',
                notifySubscribersLiveStream: 'Notifier les abonnés de nouvelles diffusions en direct',
                isCountSubscribersHidden: "Masquer le nombre d'abonnés",
                isCountryHidden: 'Masquer le pays',
                isBirthdateHidden: 'Masquer la date de naissance',
                isFullNameHidden: 'Masquer le nom complet',
                isOnlineStatusHidden: 'Masquer le statut en ligne',
                enableModeration: 'Modération',
                errors: {
                    invalidPayoutPercent: 'Veuillez saisir un pourcentage de frais valide (1 - 90)',
                },
            },
            messengerSettings: {
                disableSending: "Désactiver l'envoi",
                allowUsersToSendMedia: 'Autoriser les utilisateurs à envoyer des médias',
                limitNumberOfMessagesPerMonth: 'Limite de messages par mois',
                limitNumberOfCharacters: "Limiter le nombre de caractères par message",
                maxNumberOfMessagesPerMonth: 'Nombre maximal de messages par mois',
                maxCharacterCountPerMessage: 'Nombre maximal de caractères par message',
                invalidNumber: 'Veuillez entrer un numéro valide',
                success: 'Les paramètres de messagerie ont été mis à jour avec succès',
                error: "Une erreur s'est produite lors de la mise à jour des paramètres de messagerie",
            },
        },
        message: {
            edit: {
                success: 'Le créateur a été mis à jour avec succès !',
                error: "Le créateur n'a pas été mis à jour, veuillez réessayer !",
            },
            add: {
                success: 'Le créateur a été créé avec succès !',
                error: "Le créateur n'a pas été créé, veuillez réessayer !",
            },
        },
        menu: {
            account: 'Compte',
            dashboard: 'Tableau de bord',
            personal: 'Informations personnelles',
            globalSettings: 'Paramètres globaux',
            messengerSettings: 'Paramètres de messagerie',
            subscription: 'Abonnement',
            plans: 'Plans',
            subscribers: 'Abonnés',
            privacySecurity: 'Confidentialité et sécurité',
            privacy: 'Confidentialité',
            password: 'Mot de passe',
        },
    },
    landing: {
        "amazing": "{creator} est en train de créer quelque chose d'incroyable !",
        "backSoon": "Reviens plus-tard !",
        "takeMeHome": "Retour à l'accueil",
        startExperience: "Commencer l'expérience",
        youAreSubscribed: 'Vous êtes abonné !',
        billed: 'Facturé',
        save: 'Économisez!',
        mostPopular: 'LE PLUS POPULAIRE',
        interval: {
            monthly: 'Mensuel',
            month: 'Mensuel',
            quarterly: 'Trimestriel',
            yearly: 'Annuel',
            year: 'Mensuel',
        },
        login: 'Connexion',
        logout: 'Déconnexion',
        global: {
            about: 'À propos',
            faqs: 'FAQ',
            gallery: {
                title: 'Galerie de {name}',
                startExperience: "Commencer l'expérience",
            },
            selectSubscriptionPlan: "Sélectionnez le plan d'abonnement",
        },
    },
    payment: {
        defaultPerks: {
            1: "Débloquez tout mon contenu",
            2: "Contenu exclusif régulier",
            3: "Accès à ma messagerie privée"
        },
        global: {
            price: 'Prix',
            included: 'Inclus',
            plan: "Plan de {creator}",
            landingPage: "Retour au Fil d'actualité",
            online: 'en ligne',
            startingSoon:
                'Hey ! Vous êtes à 1 minute de commencer une nouvelle expérience à mes côtés. Rejoignez-moi dès maintenant !',
            startingSoonPayment: `Hey! Je ne suis pas encore prêt à accepter des abonnements pour le moment. Reviens bientôt !`,
            rightTitle: 'Inscription & Paiement',
            rightTitleStep2: 'Entrez les informations de votre carte',
            leftTitle: "Plan d'abonnement sélectionné",
            yourPaymentInformation: 'Vos informations de paiement',
            endingWith: 'se terminant par',
            secure: 'Paiement 100% sécurisé',
            logo: 'Logo Ikonstar',
            subLogo: 'Ikonstar',
            poweredBy: 'Propulsé par',
            success: {
                logoAlt: 'Ikonstar',
                title: 'Félicitations !',
                subTitle: 'Votre paiement a été effectué avec succès.',
                content: '',
                goToLogin: 'Connectez-vous avec mon compte',
                goToDashboard: 'Accéder à mon tableau de bord',
            },
            links: {
                privacy: 'Politique de confidentialité',
                terms: 'Conditions de services',
                termsUse: "Conditions d'utilisation",
                billing: 'Conditions de facturation',
            },
            interval: {
                monthly: 'mois',
            },
        },
        form: {
            join: 'REJOIGNEZ-MOI MAINTENANT !',
            joinHelper: 'Bienvenue sur le profil{br}de {creator} !',
            tcs: "J'accepte les {0}",
            tcsRequired: "Vous devez accepter les conditions d'utilisation",
            username: {
                label: "Nom d'utilisateur",
                placeholder: "Nom d'utilisateur",
            },
            firstName: {
                label: 'Prénom',
                placeholder: 'Prénom',
                customError: 'Prénom est un champs obligatoire'
            },
            lastName: {
                label: 'Nom de famille',
                placeholder: 'Nom de famille',
                customError: 'Nom de famille est un champs obligatoire'
            },
            name: {
                label: 'Nom Prénom',
                placeholder: 'Nom Prénom',
                customError: 'Nom Prénom est un champs obligatoire'
            },
            email: {
                label: 'Email',
                placeholder: 'Email',
            },
            country: {
                label: 'Pays',
                placeholder: 'Pays',
            },
            postalCode: {
                label: 'Code postal',
                placeholder: 'Code postal',
            },
            button: {
                join: "S'inscrire",
                submitStep1: {
                    label: 'Cartes de crédit et de débit',
                },
                submitStep2: {
                    label: 'Valider mon paiement',
                },
            },
        },
    },
    post: {
        isPinned: "Publication épinglée",
        repost: "Reposter",
        repostContent: "Regardez cet article incroyable!",
        doNotClose: 'NE PAS FERMER CETTE PAGE LORS DU TÉLÉCHARGEMENT D\'UN MÉDIA',
        previewTab: {
            all: 'TOUS',
            sub: 'ABONNÉ(E)',
            notSub: 'NON ABONNÉ(E)',
        },
        form: {
            add: {
                postTitle: 'Nouvelle publication',
                post: 'Publication',
                postTeaser: 'Teaser pour les non-abonnés',
                teaserMandatory: 'Obligatoire avec une vidéo *',
                teaserVideo: 'Vous pouvez télécharger des images ou des vidéos. Les vidéos sont limitées à 1 minute maximum.',
                releaseLater: 'Programmer la publication',
                access: {
                    title: 'Qui peut accéder à la publication',
                    everyone: 'Disponible pour tout le monde',
                    free: 'Disponible pour tout le monde',
                    subscriber: 'Abonnés uniquement',
                    subOtp: 'Abonnés ou paiement unique',
                    otp: 'Paiement unique',
                },
                postPrice: 'Prix de la publication',
                enableComment: 'Activer les commentaires ?',
                disableComment: 'Désactiver les commentaires',
                isPinned: "Épingler la publication en premier ?",
                titleNewEmpty: "Vous n'avez pas encore de publications.",
                helperNewEmpty: 'Créez votre première !',
                titleNewPost: "Nouveau post",
                action1: 'Télécharger un audio',
                action2: 'Télécharger une photo/vidéo',
                action3: 'Faire un sondage',
                action4: 'Faire un texte',
                modalTitle: {
                    post: 'Écrire un texte',
                    audio: 'Ajouter un audio',
                    photo: 'Ajouter une photo/vidéo',
                },
                modalHelper: {
                    content: {
                        post: 'Écrivez votre texte',
                        audio: 'Description',
                        photo: 'Description',
                    },
                },
                poll: {
                    toggle: 'Sondage',
                    add: '+ Ajouter une réponse',
                    question: 'Question du sondage',
                    option: 'Réponses du sondage',
                    expirationAt: 'Le sondage expire-t-il?',
                    questionError: 'Une question ou un media est requis pour un sondage !',
                    answersError: 'Une réponse du sondage est vide',
                },
                title: 'Mon titre',
                content: 'Écrivez quelque chose',
                price: 'Ajouter un prix ?',
                file: 'Ajouter un média',
                titleHelper: 'Titre',
                priceHelper: 'Prix',
                mediaHelper: 'Média',
                publish: 'Publier',
                preview: 'Prévisu. Post',
                previewDisabled: 'La prévisualisation est désactivée durant l\'encodage des vidéos. Veuillez revenir dans quelques instants.',
                labelIdle: 'Cliquez pour sélectionner ou déposez un fichier ici...',
                messageFileNotAllowed: 'Type de fichier non autorisé',
                success: 'Le message a été créé avec succès !',
                error: "Le message n'a pas été créé, veuillez réessayer !",
                contentRequired: 'Le contenu',
                titleRequired: 'Le titre',
                priceFormat: 'Le prix',
                mediaRequired: 'Le média est requis',
                saving: 'Enregistrement...',
                mention: 'Créera un lien vers ce créateur :',
            },
            edit: {
                postTitle: 'Editer une publication',
                post: 'PUBLICATION',
                postTeaser: 'PUBICATION TEASER',
                releaseLater: 'PUBLIER PLUS-TARD',
                media: 'Media associé',
                publish: 'Publier',
                access: {
                    title: 'QUI PEUT ACCÉDER A LA PUBLICATION',
                    everyone: 'Disponible pour tout le monde',
                    subscriber: 'Abonnés uniquement',
                    subOtp: 'Abonnés ou paiement unique',
                    otp: 'Paiement unique',
                },
                postPrice: 'Prix de la publication',
                disableComment: 'DESACTIVER LES COMMENTAIRES',
                poll: {
                    toggle: 'Sondage',
                    add: '+ Ajouter une réponse',
                    question: 'QUESTION DU SONADGE',
                    option: 'REPONSES DU SONDAGE',
                    expirationAt: 'EXPIRATION',
                    questionError: 'Une question ou un media est requis pour un sondage !',
                    answersError: 'Une réponse du sondage est vide',
                },
                content: 'Écrivez quelque chose',
                file: "Ajouter un média pour changer l'actuel",
                modalTitle: {
                    post: 'Modifier un texte',
                    audio: 'Modifier un audio',
                    photo: 'Modifier une photo/vidéo',
                },
                modalHelper: {
                    content: {
                        post: 'Écrivez votre texte',
                        audio: 'Description',
                        photo: 'Description',
                    },
                },
                changeMedia: "Vous devez d'abord supprimer le média existant",
                deleteMedia: {
                    title: 'Êtes-vous sur de vouloir supprimer le média ?',
                    cancelDelete: 'Annuler',
                    confirmDelete: 'Supprimer',
                    success: `Le média a été supprimé avec succès !`,
                    error: `Le média n'a été supprimé, veuillez ressayer plus tard !`,
                },
                saving: 'Enregistrement...',
            },
            update: {
                success: 'Le message a été mis à jour avec succès !',
            },
            comment: {
                add: 'Envoyer un commentaire',
                max: 'Le commentaire ne peut pas être plus long que {max} caractères',
                edit: 'Éditer votre commentaire',
                success: 'Le commentaire a été créé avec succès !',
                previewMode: "Vous ne pouvez pas commenter en prévisualisation"
            },
            title: 'Je veux publier quelque chose',
            publish: 'Publier',
            videoTeaserError: "Lors du téléchargement d'une vidéo réservée aux abonnés, une image de teaser est obligatoire",
            maxTeaserVideoDuration: 'Une vidéo pour les non-abonné(e)s est limitée à 1 minute',
        },
        unlock: 'Débloquer le contenu',
        online: 'En ligne',
        like: "J'aime",
        liked: "J'aime",
        comment: 'Commenter',
        replies: {
            action: 'Répondre',
            buttonAdd: 'Ajouter une réponse',
            show: 'Afficher les réponses ({count})',
            hide: 'Masquer les réponses',
        },
        edit: 'Modifier',
        delete: 'Supprimer',
        pin: 'Épingler sur votre profil',
        unpin: 'Désépingler de votre profil',
        titleDelete: 'Êtes-vous sûr de vouloir supprimer ?',
        confirmDelete: 'Supprimer',
        cancelDelete: 'Annuler',
        deleteSuccess: 'Votre message a été supprimé avec succès !',
        deleteError: "Votre message n'a pas été supprimé, réessayez !",
        today: "Aujourd'hui",
        yesterday: 'Hier',
        unlockContent: 'Débloquer le contenu',
        title: 'Message de {name}',
        poll: {
            expires: 'expire ',
            expired: 'expiré',
        },
        readMore: {
            false: 'Lire plus',
            true: 'Refermer',
        },
        buttonCollapse: {
            more: '+ voir plus',
            less: '- voir moins',
        },
        notReleased: 'Publications programmées',
    },
    plan: {
        plans: {
            monthly: {
                title: 'Mensuel',
                interval: 'Chaque mois',
            },
            month: {
                title: 'Mensuel',
                interval: 'Chaque mois',
            },
            quarterly: {
                title: 'Trimestriel',
                interval: 'Tous les 3 mois',
            },
            yearly: {
                title: 'Annuel',
                interval: 'Tous les 12 mois',
            },
            year: {
                title: 'Annuel',
                interval: 'Tous les 12 mois',
            },
        },
        form: {
            list: {
                title: 'Changer mon abonnement',
                status: {
                    title: 'Statut',
                    values: {
                        enabled: 'Actif',
                        disabled: 'Désactivé',
                    },
                },
                button: {
                    edit: {
                        label: 'Modifier le plan',
                    },
                },
            },
            edit: {
                title: 'Modifier mon plan',
                price: {
                    label: 'Prix',
                    formatError: 'Format du prix invalide.',
                },
                isEnabled: {
                    label: 'Est activé',
                },
                button: {
                    submit: {
                        label: 'Enregistrer',
                    },
                },
                message: {
                    success: 'Votre plan a été mis à jour avec succès.',
                },
            },
        },
    },
    planPerk: {
        title: {
            edit: "Avantages de l'abonnement",
        },
        form: {
            add: {
                title: 'Ajouter un nouvel avantage',
                text: {
                    placeholder: 'Description',
                    label: 'Description',
                },
                message: {
                    success: 'Votre avantage du plan a été ajouté avec succès.',
                    error: "Votre avantage du plan n'a pas été ajouté avec succès.",
                },
            },
            edit: {
                title: 'Modifier un avantage du plan',
                text: {
                    placeholder: 'Description',
                    label: 'Description',
                },
                button: {
                    submit: {
                        label: 'Enregistrer',
                    },
                    cancel: {
                        label: 'Annuler',
                    },
                    edit: {
                        label: 'Modifier {lang}',
                    },
                },
                message: {
                    success: 'Votre avantage du plan a été mis à jour avec succès.',
                    error: "Votre avantage du plan n'a pas été mis à jour avec succès.",
                },
            },
            delete: {
                title: 'Supprimer un avantage du plan',
                button: {
                    submit: {
                        label: 'Oui, supprimez-le',
                    },
                    cancel: {
                        label: 'Annuler',
                    },
                },
                message: {
                    success: 'Votre avantage du plan a été supprimé avec succès.',
                    error: "Votre avantage du plan n'a pas été supprimé avec succès.",
                },
            },
        },
    },
    privateVideoCall: {
        title: {
            list: 'Modifier mes appels vidéo privés',
        },
        button: {
            add: 'Créer un nouvel appel vidéo privé',
            cancel: 'Annuler',
        },
        form: {
            fields: {
                timeSlot: {
                    title: 'Durée de l\'appel (en minutes)',
                    label: '30',
                    name: 'Durée de l\'appel',
                },
                price: {
                    title: 'Prix',
                    label: '99.99',
                    name: 'Prix',
                },
                timeReminder: {
                    title: 'Envoyer un rappel X minutes avant',
                    label: '10',
                    name: 'Minuteur rappel',
                },
                action: {
                    title: '',
                },
            },
            list: {
                timeSlot: 'Créneau horaire',
                timeReminder: 'Rappel',
                price: 'Prix',
                edit: 'Modifier',
                delete: 'Supprimer',
            },
            add: {
                title: 'Créer de nouveaux appels vidéo privés',
                submit: 'Créer',
                success: "L'appel vidéo privé a été créé avec succès.",
                error: "Une erreur s'est produite lors de la création de votre appel vidéo privé.",
            },
            edit: {
                title: 'Modifier un appel vidéo privé',
                submit: 'Enregistrer',
                success: "L'appel vidéo privé a été modifié avec succès.",
                error: "Une erreur s'est produite lors de la mise à jour de votre appel vidéo privé.",
            },
            delete: {
                title: 'Voulez-vous vraiment supprimer cet appel vidéo privé ?',
                submit: 'Oui, supprimer',
                success: "L'appel vidéo privé a été supprimé avec succès.",
                error: "Une erreur s'est produite lors de la suppression de votre appel vidéo privé.",
            },
        },
    },
    liveStreaming: {
        start: "Démarrer un Live Streaming",
        publish: {
            welcome: 'Bonjour à tous, profitez bien du stream !',
        },
        helper: {
            notPublished:
                "Vous n'êtes pas encore en live, vous pouvez choisir vos options avant de publier votre live !",
            comments: 'Si vous désactiver les commentaires, il ne sera pas possible de les activer durant le live !',
            chat: "5 minutes d'inactivité supprimeront le direct et le chat",
            chatBox: 'Le chat se trouvera ici',
            audio: 'Le créateur a coupé son micro, ça revient bientôt...',
            video: 'Le créateur a coupé sa caméra, ça revient bientôt...',
            visibility: {
                subscriber: "Le live est visible uniquement par vos abonnés, vous pouvez l'ouvrir à toute le monde en cliquant sur",
                free: 'Le live sera ouvert à tous, vous pouvez le réserver à vos abonnés en cliquant sur',
            },
        },
        chat: {
            helper: 'Vous avez été mute',
            muted: 'a été mute !',
            unmuted: 'a de nouveau la parole !',
            joined: 'a joint le live :)',
            leaved: 'a quitté le live :) :(',
        },
        hangUp: {
            title: 'Etes-vous sur de vouloir arrêter le live ?',
            confirm: 'Oui',
            cancel: 'Annuler',
        },
        errors: {
            camera: 'Pouvez-vous connecter une caméra pour démarrer le stream ?',
            cameraConfirm: "C'est fait !",
            microphone: "Vous avez besoin d'un micro pour démarrer le live",
            permission: 'Autorisation refusée par le système, veuillez vérifier vos paramètres de confidentialité pour utiliser votre caméra et votre microphone !',
        },
    },
    payout: {
        title: 'Demande de paiement',
        currentSolde: 'Votre solde disponible actuel est de',
        yourAccountManager: 'Votre gestionnaire de compte',
        submit: 'Demander un paiement',
        success: 'Votre demande de paiement a été effectuée avec succès.',
        showMore: "Voir tout l'historique des paiements",
        form: {
            fields: {
                file: {
                    title: 'Importez votre facture',
                    label: 'Importer votre facture',
                    name: 'facture',
                    placeHolder: 'Téléchargez votre facture',
                },
                date: {
                    title: 'Date',
                },
                amount: {
                    title: 'Montant',
                },
                invoiceNumber: {
                    title: 'Numéro de facture',
                },
                action: {
                    title: '',
                },
            },
        },
    },
    statistic: {
        title: 'Statistique',
        choosePeriod: 'Choisissez la période',
        stats: {
            amountEarned: {
                title: 'Vous avez gagné',
            },
            subscribedNumber: {
                title: "Nombre d'abonnés",
            },
        },
    },
    coverImage: {
        title: {
            edit: 'Votre image de couverture',
        },
        form: {
            edit: {
                change: {
                    label: 'Changer votre image de couverture',
                    placeholder: 'Cliquez pour sélectionner ou déposez un fichier ici',
                },
                currentCover: {
                    label: 'Image de couverture actuelle',
                    error: {
                        required: 'Le fichier est requis',
                    },
                },
                button: {
                    submit: {
                        label: 'Enregistrer votre image de couverture',
                    },
                },
                message: {
                    success: 'Votre image de couverture a été téléchargée avec succès.',
                    error: "Une erreur s'est produite lors du téléchargement de votre image de couverture.",
                },
            },
        },
    },
    socialNetwork: {
        title: {
            edit: 'Modifier mes réseaux sociaux',
        },
        form: {
            edit: {
                title: 'Modifier mon réseau social',
                type: {
                    label: 'Réseau social',
                },
                username: {
                    label: "Nom d'utilisateur",
                },
                link: {
                    label: 'Lien',
                },
                button: {
                    edit: {
                        label: 'Modifier',
                    },
                    remove: {
                        label: 'Supprimer',
                    },
                    add: {
                        label: 'Ajouter un réseau social',
                    },
                    submit: {
                        label: 'Enregistrer',
                    },
                },
                message: {
                    success: 'Votre réseau social a été mis à jour avec succès.',
                },
            },
            add: {
                title: 'Ajouter un réseau social',
                type: {
                    label: 'Réseau social',
                },
                username: {
                    label: "Nom d'utilisateur",
                },
                link: {
                    label: 'Lien',
                },
                button: {
                    submit: {
                        label: 'Créer',
                    },
                },
                message: {
                    success: 'Le réseau social a été créé avec succès.',
                },
            },
            delete: {
                title: 'Supprimer un réseau social',
                button: {
                    submit: {
                        label: 'Supprimer',
                    },
                    cancel: {
                        label: 'Annuler',
                    },
                },
                message: {
                    success: 'Le réseau social a été supprimé avec succès.',
                },
            },
        },
    },
    module: {
        title: {
            edit: 'Gérer mes modules',
        },
        form: {
            edit: {
                save: 'Sauvegarder',
                saving: 'Enregistrement...',
                success: 'Configuration des modules enregistrées avec succés !',
                error: "Une erreur s'est produite durant l'enregistrement des modifications !",
            },
        },
    },
    messenger: {
        title: {
            edit: 'Gérer votre messagerie',
        },
        form: {
            edit: {
                save: 'Sauvegarder',
            },
        },
    },
    availability: {
        titleTimezone: 'Votre timezone',
        title: 'Votre disponibilité',
        helper: 'Définissez les heures auxquelles vous êtes disponible',
        notAvailable: 'Je ne suis pas disponible',
        button: 'Enregistrer ma disponibilité',
        success: 'Votre disponibilité a été enregistrée avec succès !',
        error: "Votre disponibilité n'a pas été enregistrée avec succès, réessayez plus tard !",
        days: {
            mon: 'Lun',
            tue: 'Mar',
            wed: 'Mer',
            thu: 'Jeu',
            fri: 'Ven',
            sat: 'Sam',
            sun: 'Dim',
        },
        delete: {
            title: 'Etes-vous sur de vouloir supprimer ce créneau ?',
            confirm: 'Oui',
            cancel: 'annuler',
        },
    },
    onboarding: {
        title: 'Configurer votre compte IKONSTAR',
        welcome: 'Bienvenue {name}',
        form: {
            step1: {
                title: 'Dites-en nous plus sur vous',
                firstname: 'Prénom',
                lastname: 'Nom',
                birthdate: 'Date de naissance',
                gender: 'Genre',
            },
            step2: {
                title: 'Choisissez vos modules',
                helper: {
                    workouts: "Séances d'exercices ciblés et programmes d'entraînement sportif.",
                    gallery: 'Partage privé de photos entre vos membres et vous.',
                    recipes: 'Ensemble de recettes pour un entraînement complet.',
                    polls: 'Proposez des sondages dans vos posts à vos membres.',
                    'private-video-calls': "Proposez la vente d'appels video en direct à vos membres.",
                    masterclass: 'Modules de leçons pour partager vos compétences et vos connaissances',
                    podcasts: 'Séries audio/vidéo informatives couvrant divers sujets',
                },
            },
            step3: {
                title: 'Nous allons passer aux choses sérieuses !',
                username: 'Pseudo',
                slug: 'Slug',
                postalCode: 'Code postal',
                country: 'Pays',
                timezone: 'Timezone',
                socials: 'Réseaux sociaux',
                errors: {
                    required: '{0} est obligatoire',
                    link: "{0} n'est pas un lien valide",
                },
            },
            step4: {
                title: "Sélectionnez ou personnalisez l'abonnement proposé aux utilisateurs",
                monthly: 'par mois',
                currency: 'Choisir la devise par défaut',
                custom: 'Autre prix mensuel',
            },
        },
        skip: 'Passer',
        next: 'Suivant',
        numberTypeError: 'Veuillez saisir un nombre valide',
        invalidFirstname: 'Veuillez saisir un prénom valide',
        invalidLastname: 'Veuillez saisir un nom valide',
        invalidGender: 'Veuillez choisir un genre',
        requiredGender: 'Veuillez choisir un genre',
        invalidUsername: 'Veuillez saisir un pseudo valide',
        invalidSlug: 'Veuillez saisir un slug valide',
        invalidCountry: 'Veuillez choisir un pays',
        requiredCountry: 'Veuillez choisir un pays',
        invalidTimezone: 'Veuillez choisir un timezone',
        requiredTimezone: 'Veuillez choisir un timezone',
        invalidBirthdate: 'Veuillez saisir une date de naissance',
        requiredCurrency: 'Veuillez choisir une devise',
        invalidCurrency: 'Veuillez choisir une devise',
        requiredSocial: 'Le réseau social est requis',
        requiredSocialName: "Le nom d'utilisateur est requis",
        invalidPrice: 'Veuillez saisir un prix égal ou supérieur à 4,99',
        success: "Vos informations d'onboarding ont été enregistrées avec succès",
    },
    faq: {
        title: {
            edit: 'Mes FAQ',
        },
        form: {
            add: {
                title: 'Créer une nouvelle question (en anglais)',
                question: {
                    label: 'Question',
                },
                answer: {
                    label: 'Réponse',
                },
                button: {
                    add: {
                        label: 'Créer une nouvelle question',
                    },
                    submit: {
                        label: 'Créer',
                    },
                },
                message: {
                    success: 'La question a été créée avec succès.',
                },
            },
            edit: {
                title: 'Modifier une question ({lang})',
                question: {
                    label: 'Question',
                },
                answer: {
                    label: 'Réponse',
                },
                button: {
                    submit: {
                        label: 'Enregistrer',
                    },
                    cancel: {
                        label: 'Annuler',
                    },
                },
                message: {
                    success: 'La question a été modifiée avec succès.',
                },
            },
            delete: {
                title: 'Voulez-vous vraiment supprimer cette question ?',
                button: {
                    submit: {
                        label: 'Oui, supprimer',
                    },
                    cancel: {
                        label: 'Annuler',
                    },
                },
                message: {
                    success: 'La question a été supprimée avec succès.',
                },
            },
        },
    },
    "stripeConnect": {
        "title": "Hey {name}, votre compte partenaire Stripe est maintenant configuré !",
        "subtitle": "",
        "next": "Les utilisateurs peuvent désormais s'abonner à votre profil !",
        "button": "Retour au Fil d'actualité"
    },
    stripeIdVerification: {
        error: 'Il semble que quelque chose se soit mal passé. Veuillez réessayer.',
        form: {
            title: 'Hey {name}, vérifions votre compte !',
            step1: 'Spécifiez la configuration de compte souhaitée',
            step2: 'Validez votre identification',
            step3: 'Validez votre adresse',
            button: {
                next: 'Suivant',
                previous: 'Précédent',
                verifyMe: 'VERIFIER MON IDENTITE',
                submit: 'Envoyer',
            },
            accountType: {
                title: 'Spécifiez la configuration de compte souhaitée.',
                personal: {
                    title: 'Compte Personnel',
                    description: "Vérifiez en tant que 'Compte Personnel' si vous n'avez pas encore de société.",
                },
                professional: {
                    title: 'Compte Professionnel',
                    description: 'Choisissez cette option si vous avez une société.',
                    subtitle: 'Parlez-nous davantage de votre société',
                },
            },
            inputs: {
                required: '{field} est obligatoire',
                company: 'Nom de la société',
                company_number: "Numéro d'entreprise",
                company_vat: 'Numéro de TVA de la société',
                address_1: 'Adresse de la société',
                address_2: 'Adresse de la société (suite)',
                postcode: 'Code postal de la société',
                city: 'Ville de la société',
                country_id: 'Pays de la société',
                country: 'Choisir un pays',
            },
            verify: {
                title: 'Vérifiez votre identification',
            },
        },
        titles: {
            created: 'Vérifiez votre identité pour que votre profil puisse être accessible.',
            processing: 'Votre identité est en cours de vérification.',
            verified: 'Votre identité est maintenant vérifiée.',
            requires_input: 'Vérifiez votre identité pour que votre profil puisse être accessible.',
            canceled: 'Vérifiez votre identité pour que votre profil puisse être accessible.',
            redacted: 'Vérifiez votre identité pour que votre profil puisse être accessible.',
        },
        errors: {
            consent_declined:
                "L'utilisateur a refusé d'être vérifié par Stripe. Votre compte ne peut pas être vérifié sans cela.",
            under_supported_age: "Stripe ne vérifie pas les utilisateurs de moins de l'âge de la majorité.",
            country_not_supported: 'Stripe ne vérifie pas les utilisateurs du pays fourni.',
            document_expired: "Le document d'identité fourni a expiré.",
            document_unverified_other: "Stripe n'a pas pu vérifier le document d'identité fourni.",
            document_type_not_supported:
                "Le document d'identité fourni n'est pas l'un des types de documents autorisés dans la session.",
            selfie_document_missing_photo: "Le document d'identité fourni ne contenait pas de photo d'un visage.",
            selfie_face_mismatch: "L'image du visage capturée ne correspondait pas au visage du document.",
            selfie_unverified_other: "Stripe n'a pas pu vérifier le selfie fourni.",
            selfie_manipulated: "L'image du visage capturée a été manipulée.",
            id_number_unverified_other:
                "Stripe n'a pas pu vérifier le numéro d'identification fourni. Voir la liste des numéros d'identification pris en charge.",
            id_number_insufficient_document_data:
                "Le document fourni ne contenait pas suffisamment de données pour correspondre au numéro d'identification.",
            id_number_mismatch:
                "Les informations fournies n'ont pas pu être vérifiées par rapport aux bases de données mondiales.",
        },
        review: {
            title: 'Hey {name}, la vérification de votre identité est maintenant en cours de révision',
            subtitle: 'Nous vous informerons du résultat par e-mail',
            next: 'En attendant, vous pouvez continuer le tutoriel !',
            button: 'Retour au tutoriel',
        },
    },
    tutorial: {
        title: 'Avancement du Tutoriel',
        complete: "Vous avez terminé le tutoriel obligatoire !",
        stripeIdUnlock: "Vérification d'identité en cours",
        stripeUnlock: 'Remplissez toutes les autres tâches pour débloquer celle-ci',
        stripeCountryInfo: {
            "title": "Veuillez lire attentivement",
            "description": "Ce formulaire est l'une des étapes les plus importantes pour configurer votre compte. Les valeurs sélectionnées à cette étape seront utilisées pour créer votre compte Stripe Partner sur Ikonstar. Ces valeurs ne peuvent pas être modifiées ultérieurement. Veuillez vérifier chaque entrée minutieusement."
        },
        steps: {
            title: 'Étape {nb}',
            2: 'Vérification du compte',
            1: 'Complétez votre profil',
            3: 'Mise en ligne !',
            optional: 'Paramètres facultatifs',
        },
        avatar: {
            title: "C'est parti !",
            content: "Cliquez ici pour changer votre avatar !",
        },
        todos: {
            billing_verified: 'Vérifiez les informations de votre compte',
            id_verified: 'Vérifiez votre identité',
            avatar: 'Téléchargez un avatar',
            header: "Téléchargez une image d'en-tête",
            about: 'Remplissez votre section À propos de moi',
            bio: 'Remplissez votre section Ma Bio',
            socials: 'Ajoutez vos réseaux sociaux à votre profil',
            sub_perks: 'Complétez vos abonnements et avantages',
            five_posts: 'Créez au moins 3 publications',
            stripe_connect: 'Créez votre compte Stripe Partenaire Ikonstar',
            modules: 'Activez ou désactivez les modules de votre plateforme',
            private_video_calls: 'Configurez des tarifs pour les Appels Vidéo Privés',
            messenger_settings: 'Choisissez vos paramètres de messagerie',
        },
    },
    podcasts: {
        title: 'Podcasts',
        nowPlaying: 'EN COURS DE LECTURE',
        index: {
            empty: "On dirait que c'est votre première visite ici !",
            createFirst: 'Configurez votre page de podcasts',
            create: 'Créer un nouveau podcast',
        },
        setup: {
            title: 'Configurer ma page de podcasts',
            editButton: 'Modifier les paramètres de la page',
            formTitle: 'Titre de la page',
            formDescription: 'Description de la page',
            submit: 'Sauvegarder',
            notification: {
                add: {
                    success: 'Votre page de podcast a été configurée avec succès !',
                    error: "Votre page de podcast n'a pas été configurée, veuillez réessayer !",
                },
                update: {
                    success: 'Votre page de podcast a été mise à jour avec succès !',
                    error: "Votre page de podcast n'a pas été mise à jour, veuillez réessayer !",
                },
            },
        },
        episode: {
            createTitle: 'Créer un nouveau podcast',
            editTitle: 'Modifier le podcast',
            saveTitle: 'Sauvegarder Podcast',
            fieldset: 'Vos informations sur le podcast',
            visibility: 'Qui peut écouter ce podcast ?',
            title: 'Titre du podcast',
            description: 'Courte description du podcast',
            type: 'Type de podcast',
            video: 'Vidéo du podcast',
            audio: 'Audio du podcast',
            reorder: 'Réorganiser les podcasts',
            applyReorder: 'Appliquer le nouvel ordre',
            cancelReorder: 'Annuler',
            notification: {
                add: {
                    success: 'Votre podcast a été créé avec succès !',
                    error: "Votre podcast n'a pas été créé, veuillez réessayer !",
                },
                update: {
                    success: 'Votre podcast a été mis à jour avec succès !',
                    error: "Votre podcast n'a pas été mis à jour, veuillez réessayer !",
                },
                delete: {
                    success: 'Votre podcast a été supprimé avec succès !',
                    error: "Votre podcast n'a pas été supprimé, veuillez réessayer !",
                },
                order: {
                    success: 'Vos podcasts ont été réorganisés avec succès !',
                    error: "Vos podcasts n'ont pas été réorganisés, veuillez réessayer !",
                },
            },
        },
    },
    series: {
        episode: {
            reorder: 'Réordonner les exercices',
            applyReorder: 'Appliquer le nouvel ordre',
            cancelReorder: 'Annuler',
            notification: {
                add: {
                    success: 'Votre épisode a été créé avec succès !',
                    error: "Votre épisode n'a pas été créé, veuillez réessayer !",
                },
                update: {
                    success: 'Votre épisode a été mis à jour avec succès !',
                    error: "Votre épisode n'a pas été mis à jour, veuillez réessayer !",
                },
                delete: {
                    success: 'Votre épisode a été supprimé avec succès !',
                    error: "Votre épisode n'a pas été supprimé, veuillez réessayer !",
                },
                order: {
                    success: 'Vos épisodes ont été réorganisés avec succès !',
                    error: "Vos épisodes n'ont pas été réorganisés, veuillez réessayer !",
                },
            },
        },
    },
    privacy: {
        title: {
            edit: 'Confidentialité',
        },
        form: {
            edit: {
                visibility: 'Cacher mon profil dans le moteur de recherche',
                success: 'Vos paramétres ont été mis à jour avec succés!',
                error: "Vos paramétres n'ont pas été mis à jour! Ressayer plus-tard",
                save: 'Sauvegarder',
            },
        },
    },
}
export default creator
