const globals = {
    "allRightsReserved": "Todos los derechos reservados",
    "errors": {
        "404": {
            "title": "Parece que no hay nada aquí. ¿Estás perdido?",
            "button": "Ir a la página de inicio"
        }
    },

    "slug": "Tu perfil estará en ikonstar.com/{slug}",

    specialUnlock: "Reserva tu asiento",
    accountNotSetupLive: "¡Necesitas terminar el tutorial para usar la transmisión en vivo!",

    "confirmationDelete": "¿Estás seguro?<br>No podrás revertir esto.",
    remove: 'Eliminar',
    tryAgain: 'Inténtalo de nuevo',
    sendComment: 'Comentar',
    language: 'Lengua',
    "deleteIt": "Sí, borrarlo",
    "previous": "Anterior",
    "next": "Siguiente",
    start: 'Empezar',
    hours: "horas",
    minutes: "minutos",
    "no": "No",
    "yes": "Sí",
    'submit': "Enviar",
    close: "Cerrar",
    "enabled": "Activado",
    "disabled": "Desactivado",
    "loading": "Cargando...",
    "noMore": "No hay más contenido",
    "loadMore": "Cargar más",
    "uploadingMedia": "Subiendo medios",
    clickToClose: "Clicar para cerrar",
    details: "Detalles",
    backToProfile: "Volver a mi perfil",
    filters: "Filtros",

    "noActionPreviewMode": "No puedes hacer esto en modo de vista previa :)",

    "countdown": {
        "days": "Días",
        "hours": "Horas",
        "minutes": "Minutos",
        "seconds": "Segundos"
    },

    "menu": {
        "sidebar": {
            "newProject": "Nuevo proyecto...",
            "settings": "Configuración",
            "profile": "Perfil",
            "preview": "Vista previa",
            notification: "Notificaciones",
            "signOut": "Cerrar sesión",
            homepage: 'Volver a Inicio',
        }
    },
    notification: {
        unreadMessages: 'Mensajes no leídos',
        markAllAsRead: 'Marcar {number} como leído',
        markAsRead: 'Marcar como leído',
        delete: 'Eliminar',
        preferences: 'Ver preferencias',
        switchRead: 'Mostrar solo no leídos',
    },
    "edit": "Editar",
    "addMore": "Agregar más",
    "seeMore": 'ver más',
    "exit": "Salir",
    "validations": {
        "email": "El correo electrónico debe ser un correo electrónico válido.",
        "required": "Este campo es obligatorio.",
        "number": "El campo no es un valor numérico válido.",
        "date": "El campo no es una fecha válida.",
        "string": "El campo no es una cadena de texto válida",
        "min": "El campo debe tener al menos {min} caracteres",
        "numberMin": "El campo debe ser mayor que {min}",
        "max": "El campo permite como máximo {max} caracteres",
        "numberMax": "El campo debe ser menor que {max}",
        "recaptcha": "Por favor, verifica que eres humano"
    },
    "form": {
        "validations": {
            "email": "{label} no es un correo electrónico válido",
            "required": "{label} es obligatorio",
            requiredSelect: "Elige un {label}",
            "number": "{label} no es un valor numérico válido",
            "date": "{label} no es un valor de fecha válido",
            "string": "{label} no es una cadena de texto válida",
            "numberMin": "{label} debe ser mayor que {min}",
            "numberMax": "{label} debe ser menor que {max}",
            "min": "{label} debe contener al menos {min} caracteres",
            "max": "{label} no puede contener más de {max} caracteres",
            "recaptcha": "Por favor, verifique que es un ser humano"
        },
        "fields": {
            "email": {
                "label": "Dirección de correo electrónico"
            },
            "fullname": {
                "label": "Nombre completo"
            },
            "username": {
                "label": "Nombre de usuario"
            },
            "password": {
                "label": "Contraseña"
            },
            "title": {
                "label": "Título"
            },
            "date": {
                "label": "Fecha"
            },
            "photo": {
                "label": "Foto"
            },
            "comment": {
                "label": "Comentario"
            }
        }
    },
    "date": {
        "today": "Hoy",
        "yesterday": "Ayer",
        "at": "{date} a las {time}",
        minutes: "minutos",
    },
    "requiredInputGuide": "Los campos con * son obligatorios",
    "title": {
        "user-dashboard": "",
        "user-account": "Mi cuenta",
        "user-post-view": "Publicación",
        "user-messages": "Mis mensajes",
        "user-my-calendar": "Mi planificación personal",
        "user-calendar-add-event": "Agregar ejercicios",
        "user-private-video-call-event": "Reservar una videollamada",
        "user-calendar-congrats": "Evento de mi calendario",
        "user-workouts": "Mis entrenamientos",
        "user-view-workouts": "Mis entrenamientos",
        "user-masterclass": "Masterclasses",
        "user-view-masterclass": "Masterclasses",
        "user-view-masterclass-episode": "Masterclasses",
        "user-progress": "Mi progreso",
        "user-favorites": "Mis favoritos",
        "user-gallery": "Mi galería",
        "user-recipes": "Mis recetas",
        "user-view-recipe": "Mis recetas",
        "user-workout-program": "Programa de entrenamiento",

        "kol-admin-dashboard": "",
        "kol-admin-my-page": "",
        "kol-admin-my-feed": "Mi feed",
        "kol-admin-my-settings": "Configuración de tu perfil",
        "kol-admin-messages": "Mis mensajes",
        "kol-admin-my-calendar": "Mi planificación personal",
        "kol-admin-calendar-add-event": "Agregar evento personalizado",
        "kol-admin-my-workouts": "Mis entrenamientos",
        "kol-admin-edit-workout": "Editar un entrenamiento",
        "kol-admin-view-workout-episodes": "Mis episodios",
        "kol-admin-edit-workout-episode": "Editar un episodio",
        "kol-admin-my-masterclass": "Masterclasses",
        "kol-admin-edit-masterclass": "Editar una masterclass",
        "kol-admin-view-masterclass-episodes": "Mis episodios",
        "kol-admin-edit-masterclass-episode": "Editar un episodio",
        "kol-admin-medias-management": "Mis medios"
    },

    "tagLine": "La plataforma única para creadores de contenido",
    "product": {
        "title": "PRODUCTO",
        "forIkons": "Para íconos",
        "forFans": "Para fans",
        "forCorp": "Para corporaciones",
        "become": "Conviértete en un ícono",
        "download": "Descargar Ikonstar"
    },
    "blog": {
        "title": "BLOG",
        "fitness": "Fitness",
        "food": "Comida",
        "health": "Salud",
        "workouts": "Entrenamientos",
        "nutrition": "Nutrición"
    },
    "support": {
        "title": "SOPORTE",
        "helpCenter": "Centro de ayuda",
        "contact": "Contacto"
    },
    "about": {
        "title": "SOBRE NOSOTROS"
    },

    "global": {
        "videoStatus": {
            "processing": "El video se está procesando",
            "pending": "El video está pendiente",
            "failed": "El video ha fallado"
        },
        "form": {
            "button": {
                "submit": {
                    "label": "Enviar"
                }
            }
        }
    },

    "plans": {
        "intervals": {
            "monthly": "Plan mensual",
            "quarterly": "Plan trimestral",
            "yearly": "Plan anual"
        },
        "recurring": {
            "monthly": "mes",
            "quarterly": "trimestre",
            "yearly": "año"
        }
    },

    "enums": {
        "categories": {
            "aerobics": "Aeróbicos",
            "aqua-fitness": "Aqua Fitness",
            "boxing": "Boxeo",
            "cardio": "Cardio",
            "circuit-training": "Entrenamiento en circuito",
            "cross-fit": "CrossFit",
            "dance-fitness": "Dance Fitness",
            "hiit": "HIIT",
            "martial-arts": "Artes marciales",
            "pilates": "Pilates",
            "strength-training": "Entrenamiento de fuerza",
            "yoga": "Yoga"
        },
        "body-parts": {
            "arms": "Brazos",
            "legs": "Piernas",
            "chest": "Pecho",
            "back": "Espalda",
            "abdomen": "Abdomen",
            "shoulders": "Hombros",
            "glutes": "Glúteos",
            "full-body": "Cuerpo entero",
            "neck": "Cuello",
            "wrist": "Muñeca",
            "ankle": "Tobillo"
        },
        "goals": {
            "health": "Salud",
            "burning-fat": "Quemar grasa",
            "building-muscle": "Construir músculo",
            "increasing-endurance": "Aumentar la resistencia",
            "improving-mobility": "Mejorar la movilidad",
            "enhancing-flexibility": "Mejorar la flexibilidad",
            "stress-relief": "Alivio del estrés",
            "weight-loss": "Pérdida de peso",
            "posture-improvement": "Mejora de la postura",
            "body-toning": "Tonificación del cuerpo"
        },
        "levels": {
            "hard": "Difícil",
            "easy": "Fácil",
            "beginner": "Principiante",
            "intermediate": "Intermedio",
            "advanced": "Avanzado",
            "professional": "Profesional",
            "elderly": "Adultos mayores",
            "for-all": "Para todos"
        },
        "locations": {
            "indoor": "Interior",
            "outdoor": "Exterior",
            "gym": "Gimnasio",
            "home": "Casa",
            "office": "Oficina",
            "swimming-pool": "Piscina",
            "fitness-studio": "Estudio de fitness"
        }
    },

    "superAdmin": {
        "form": {
            "search": {
                "label": "Buscar",
                "placeholder": "Buscar..."
            },
            "button": {
                "resetFilters": {
                    "label": "Restablecer filtros"
                }
            }
        }
    },
    "pwa": {
        "title": "¡Instala la aplicación para recibir notificaciones!",
        "description": "Instala {0} en tu dispositivo iOS. Toca compartir y luego {1}",
        "addToHome": "Agregar a la pantalla de inicio",
        "updateInfo": "¡Una nueva versión de Ikonstar está disponible! Si estás trabajando en algo importante, puedes actualizar tu navegador más tarde.",
        "updateNow": "Actualizar ahora",
        "updateLater": "Actualizar más tarde"
    },
    messengerSubscriber: "Este módulo solo está disponible para suscriptores.",
    noMobileModule: "Este módulo solo está disponible en computadoras o tabletas.",
    previewMode: "Estás ahora en modo de vista previa. Haz clic aquí para detenerlo.",
    stopPreviewMode: "Por favor, detener la vista previa.",
    impersonating: "Ahora estás personificando a {username}. Haz clic aquí para detener",

    share: {
        title: 'Compartir',
        copy: 'Copiar',
        success: 'Enlace copiado al portapapeles',
    },

    string: {
        success: 'Copiado al portapapeles',
    },

    tooltips: {
        verifiedAccount: 'Cuenta Verificada',
        like: 'Me gusta',
        share: 'Compartir',
        deletePreviewLink: "Eliminar vista previa del enlace",
        disablePreviewLink: "Desactivar vista previa del enlace",
        totalViews: 'Vistas totales',
    },

    "leave": {
        "title": "¿Realmente quieres irte? ¡Tienes cambios sin guardar!",
        "stay": "Quedarse",
        "leave": "Salir"
    },
    modules: {
        workouts: 'Sesión de ejercicios específica dentro de un programa de entrenamiento',
        gallery: 'Compartir fotos entre tus miembros y tú.',
        recipes: 'Conjunto de Recetas para un Entrenamiento Completo',
        polls: 'Incluir encuestas en tus publicaciones para tus miembros.',
        'private-video-calls': 'Ofrecer llamadas en vivo a tus miembros.',
        masterclass: 'Módulos de lecciones para compartir tus habilidades y conocimientos',
        podcasts: 'Series de audio/video informativas que cubren diversos temas',
        'live-events': 'Transmisión de eventos en vivo con sistema de venta de entradas.'
    },

    draft: {
        title: 'Borradores',
        helper: 'tus borradores solo están disponibles en este dispositivo',
        noItems: "no tienes borradores guardados",
        noTitle: 'No iniciado...',
        button: 'Lista de borradores',
        continue: 'Continuar',
        deleteSuccess: '¡Tu borrador fue eliminado con éxito!',
        deleteError: "Tu borrador no fue eliminado, ¡intenta de nuevo!",
    },

    search: {
        landingTitle: "¿Estás buscando {gold}?{br}¡Estás en el lugar correcto!",
        landingTitleGold: "creadores de Ikonstar",
        landingSubtitle: "Introduce su nombre de usuario y empieza a acceder{br}a su contenido exclusivo",
        input: {
            placeholder: "Buscar un creador"
        },
        allResults: "Ver todos los resultados"
    },

    feed: {
        forYou: "Para ti",
        following: "Siguiendo"
    },

    signup: {
        title: "Crea una cuenta"
    }
}


export default globals
