const user = {
    menu: {
        hi: 'Hi,',
        signOutKolSelect: "It's not me, logout",
        home: 'Home',
        feed: 'Feed',
        dashboard: 'Dashboard',
        messages: 'Messenger',
        masterclasses: 'Masterclasses',
        workouts: 'Workouts',
        myCalendar: 'My Calendar',
        post: 'Post',
        nutritionGuides: 'Nutrition Guides',
        myPlan: 'My Plan',
        myProgress: 'My Progress',
        photoGallery: 'Photo Gallery',
        myFavorites: 'My Favorites',
        myGallery: 'My Gallery',
        recipes: 'Recipes',
        viewAccount: 'View Account',
        changeIkon: 'Switch my Ikonstar',
        selectAnIkon: 'Change Ikon in a few clicks!',
        followedIkon: 'You are currently following',
        myAccount: 'My Account',
        signOut: 'Sign out',
        creator: {
            select: 'Select your Ikonstar',
            viewingNow: 'Viewing now',
            changeSuccess: `You are now viewing {name}`,
        },
    },
    user: {
        account: {
            title: 'Your profile settings',
            menu: {
                account: 'Account',
                personal: 'Personal Info',
                password: 'Password',
                subscription: 'Subscriptions',
                settings: 'My settings',
                language: 'Language',
                notification: 'Notification',
                privacy: 'Privacy',
            }
        },
        form: {
            notificationManager: {
                title: 'Notification manager',
                allowNotification: 'Enable Notifications',
                selectAll: 'Select/Unselect all',
                event: 'Event',
                submit: 'Save my preferences',
                success: 'Your notification preferences are successfully updated.',
                enabled: 'Notifications for {creator} enabled',
                disabled: 'Notifications for {creator} disabled',
                error: 'There was an error while updating your notification preferences.',
            },
            language: {
                title: 'Your preferred language',
                field: 'Language',
                submit: 'Change language',
            },
            personalInfo: {
                title: 'Your personal information',
                changeEmail: {
                    linkHelper: 'You can change your email',
                    link: 'here',
                    confirm: {
                        title: 'Are you sure you want to change your email?',
                        submit: 'Yes, do it!',
                        cancel: 'cancel',
                    },
                    title: {
                        step1: 'What is the new email address?',
                        step2: 'Please check your email',
                        step3: 'New email has been set',
                    },
                    helper: {
                        step1: 'We will send a verification code to your new email address',
                        step2: "We've sent a code to",
                        step3: "You will be logged out to apply the changes",
                    },
                    sendCode: 'Send code',
                    verify: 'Verify',
                    great: 'Great',
                },
                fields: {
                    username: {
                        label: 'Username',
                        name: 'username',
                    },
                    name: {
                        label: 'Full name',
                        name: 'name',
                    },
                    email: {
                        label: 'Email',
                        name: 'email',
                    },
                    code: {
                        label: 'Code',
                    },
                    slug: {
                        label: 'Slug',
                        name: 'slug',
                        validations: {
                            format: 'Your slug needs to start with letters, can only contain lowercase letters, numbers and dashes.',
                        },
                    },
                    gender: {
                        label: 'Gender',
                        name: 'gender',
                        validations: {
                            match: 'The selected gender is invalid.',
                        },
                    },
                    birthdate: {
                        label: 'Birth date',
                        name: 'birth date',
                        validations: {
                            invalid: 'The birth date is invalid.',
                        },
                    },
                    website: {
                        label: 'Website',
                        name: 'website',
                    },
                    phone: {
                        label: 'Phone',
                        name: 'phone',
                    },
                    about: {
                        label: 'About',
                        name: 'about',
                    },
                    bio: {
                        label: 'Bio',
                        name: 'bio',
                    },
                    old_password: {
                        label: 'Old password',
                        name: 'old password',
                    },
                    password: {
                        label: 'Password',
                        name: 'password',
                    },
                    password_confirmation: {
                        label: 'Password confirmation',
                        name: 'password_confirmation',
                        validations: {
                            match: 'Passwords must match',
                        },
                    },
                },
                submit: 'Save my personal information',
                success: 'Your personal information is successfully updated.',
                successPassword: 'Your password is successfully updated.',
                error: 'There was an error while updating your personal information.',

            },
            subscription: {
                endingWith: "ending with",
                title: 'Subscription summary',
                paymentMethod: 'Payment method',
                subscriptionPrice: 'Subscription price',
                nextExpiryDate: 'Next renewal date',
                isCanceled: 'Subscription is canceled',
                subscriptionType: 'Subscription type',
                graceUntil: 'Grace period',
                save: 'Save',
                resumeButton: 'Resume my subscription',
                titleResume: 'Are you sure you want to resume?',
                confirmResume: 'Resume It',
                resumeSuccess: 'Your subscription was resumed successfully!',
                resumeError: 'Your subscription was not resumed, try again!',
                cancelButton: 'Cancel my subscription',
                titleCancel: 'Are you sure you want to cancel to subscription to {creator}?',
                confirmCancel: 'Yes, cancel',
                closeButton: 'No, do not cancel',
                cancelSuccess: 'Your subscription was canceled successfully!',
                cancelError: 'Your subscription was not canceled, try again!',
            },
        },
    },
    superAdmin: {
        menu: {
            account: 'Account',
            personal: 'Personal infos',
            management: 'Management',
            members: 'Members',
            creators: 'Creators',
            security: 'Security',
        },
        title: {
            list: 'Members',
            add: 'Add a new member',
            edit: 'Edit a member',
            ban: 'User ban',
            accountManager: 'Account Manager',
        },
        form: {
            list: {
                search: 'Search...',
                button: {
                    add: 'Add member',
                },
                filters: {
                    onlyBanned: 'Only banned',
                    onlySubscribers: 'Only subscribers',
                },
                columnMember: 'Name',
                columnRole: 'Role',
                columnStatus: 'Status',
                columnCreatedAt: 'Created At',
                columnEmail: 'Email',
                convertToCreator: 'Convert to Creator',
                editMember: 'Edit member',
                banMember: 'Ban member',
                unbanMember: 'Unban member',
                impersonate: 'Impersonate',
                confirmationBan: 'Are you sure you want to ban?',
                confirmationUnban: 'Are you sure you want to unban?',
                banIt: 'Yes, ban it!',
                unbanIt: 'Yes, unban it!',
                no: 'No',
                activateSuccess: 'This action was performed successfully!',
                activateError: "This action wasn't performed successfully!",
                confirmationConvertToCreator: 'Are you sure you want to convert this User to a Creator? This action can not be undone.',
                convertToCreatorSuccess: 'The User has been converted to Creator successfully!',
            },
            add: {
                name: `Full Name`,
                username: `Username`,
                email: `Email address`,
                birthdate: `Birth date`,
                role: `Role`,
                password: `Password`,
                matchPassword: `Confirm the password`,
                verified: `Verified`,
                job: `Job role`,
                cancel: `Cancel`,
                submit: `Submit`,
                back: `back`,
                success: `The member was created successfully!`,
                error: `The member wasn't created, please try again!`,
            },
            edit: {
                name: `Full Name`,
                firstname: `Firstname`,
                lastname: `Lastname`,
                username: `Username`,
                email: `Email address`,
                language: `Language`,
                birthdate: `Birth date`,
                role: `Role`,
                password: `Password`,
                matchPassword: `Confirm the password`,
                verified: `Verified`,
                job: `Job role`,
                cancel: `Cancel`,
                submit: `Submit`,
                back: `back`,
                success: `The member was updated successfully!`,
                error: `The member wasn't updated, please try again!`,
            },
            ban: {
                banReason: `Ban reason`,
                placeholder: `Enter the reason of the ban`,
            },
            accountManager: {
                attachKol: 'Attach a Creator',
                detachKol: 'Detach creator',
                search: 'Who are looking for ?',
                columnKol: 'Username',
                columnName: 'Full Name',
                columnCreatedAt: 'Created At',
                columnFollowers: 'Subscribers',
                modal: {
                    attach: {
                        title: 'Select a creator',
                        submit: 'Save',
                        creator: {
                            name: 'Creator',
                            label: 'Creator',
                        },
                        success: `The creator was attached successfully!`,
                        error: `The creator wasn't attached, please try again!`,
                    },
                    detach: {
                        title: 'Select a creator',
                        submit: 'Save',
                        creator: {
                            name: 'Creator',
                            label: 'Creator',
                        },
                        confirm: 'Are you sure you want to detach this creator?',
                        success: `The creator was detached successfully!`,
                        error: `The creator wasn't detached, please try again!`,
                    }
                },
            }
        }
    },
    myWorkoutProgram: {
        title: 'Workout Program',
        searchPlaceholder: 'Search a workout program',
        empty: 'There is no workout program',
        joinSuccess: 'Workout program joined successfully',
        week: 'week',
        weeks: 'weeks',
        day: 'day',
        days: 'days',
        features: 'Program features',
        recipeCategory: 'Recipe category',
        listContent: 'List of content',
        takeBreak: 'Take a break',
        followersCompleted: 'followers completed',
        filter: {
            bodyPart: 'Body Part',
            level: 'Level',
            category: 'Category',
        },
        buttons: {
            joinProgram: 'Join Program',
            joinedProgram: 'Joined Program',
        },
        message: {
            joinSuccess: 'Join program successfully',
            joinError: 'Join program unsuccessfully',
        },
        form: {
            title: 'Join this program',
            fields: {
                date: 'Starting at',
                hour: 'Select hour',
            },
        },
    },
    onboarding: {
        title: 'Set up your IKONSTAR account',
        welcome: 'Welcome {name}',
        thankYou: 'Thank you for joining {creatorName}',
        letsSetupYourAccount: 'Let’s setup your account',
        form: {
            step1: {
                title: 'Please enter your body measurements',
                yourAge: 'Your age',
                yourBirthdate: 'Your birthdate',
                years: 'years',
                yourHeight: 'Your height',
                yourWeight: 'Your weight',
                yourGender: 'Your gender',
                weightUnit: 'Weight unit',
                heightUnit: 'Height unit',
            },
            step2: {
                title: 'Please answer the following questions',
                chooseYourObjective: 'Choose your objective',
                objectives: {
                    toMaintainAHealthyBody: 'To maintain a healthy body',
                    toBeFitOrLoseWeight: 'To be fit / lose weight',
                    toGainMuscleAndCurves: 'To gain muscle & curves',
                    toDevelopStrength: 'To develop strength',
                },
            },
            step3: {
                title: 'Please answer the following questions',
                yourActivityLevel: 'Your activity level',
                activityLevels: {
                    noExercise: 'No exercise',
                    light: 'Light (1 day per week)',
                    moderate: 'Moderate (2-3 days per week)',
                    intense: 'Intense (more than 4 days)',
                },
            },
        },
        skip: 'Skip',
        next: 'Next',
        numberTypeError: 'Please enter a valid number',
        invalidGender: 'Please select a valid value',
        requiredGender: 'Please select your gender field',
        invalidHeightUnit: 'Please select a height unit',
        invalidWeightUnit: 'Please select a weight unit',
        invalidObjective: 'Please select at least an objective',
        invalidActivityLevel: 'Please select a valid activity level',
        invalidBirthdate: 'Please select a valid birthdate',
        success: 'Your onboarding information has been recorded successfully',
    },
    workouts: {
        label: 'Workouts',
        title: 'My Workouts',
        subTitle: 'Today it is you who shine',
        emptyFilter: {
            workouts: 'There is no workout with these filters',
            masterclass: 'There is no workout with these filters',
            video: 'There is no video with these filters',
        },
        empty: {
            workouts: 'did not post any workout yet',
            masterclass: 'did not post any workout yet',
            video: 'did not post any video yet',
        },
        subMenu: {
            allClasses: 'Workouts',
            allVideos: 'Videos',
            workoutProgram: 'Programs',
        },
        filter: {
            bodyPart: 'All body part',
            location: 'Location',
            duration: 'Duration',
        },
        sort: {
            label: 'Sort by',
            latestJoined: 'Latest joined',
            oldestJoined: 'Oldest joined',
        },
        search: {
            placeholder: 'Find a workout',
        },
        likes: 'likes',
        episodes: {
            label: 'Exercise',
            viewFaq: 'View FAQ',
            viewTips: 'View Tips',
            tips: {
                title: 'Do you have a question?',
                label: 'Tip',
                done: 'Done',
            },
            repsIndication: 'Sets: {current} / {total}',
        },
        modal: {
            confirmExit: 'Are you sure you want to close the exercise?',
            continue: 'Carry on exercise',
            exit: 'Exit exercise',
        },
        viewEpisode: {
            enterYourSet: 'Number of sets',
            times: 'set',
            autoPlay: 'Auto play',
            autoPlayTips: 'Auto continue to the next episode after break',
            startNow: 'Start now',
            ready: 'Ready',
            takeABreak: 'Take a break',
            nextEpisodeIn: 'Next episode in',
            nextRepIn: 'Next set in',
            requiredEquipments: 'Required equipments',
            repetitions: 'Repetitions',
        },
        programs: {
            label: 'Programs',
        },
    },
    live: {
        waiting: 'Please wait, the live start soon',
        seeLast: 'New Messages',
        finished: 'The live stream has ended, see you at the next one!',
        backToFeed: 'back to feed',
        notStarted: 'LIVE NOT STARTED YET',
        chatRateLimit: 'You are sending messages too quickly.',
        mutedTitle: 'TIMEOUT',
        mutedHelper: 'You are currently timed out from Chat.',
        mutedMessage: 'message deleted by a moderator.',
    }
}

export default user
