const creator = {
    search: 'Buscar',
    searchHelper: 'Escribe algo para buscar',
    menu: {
        dashboard: 'Tablero',
        openPage: 'Abrir Mi Página',
        myPage: 'Mi Página',
        mySettings: 'Mis Ajustes',
        myWorkouts: 'Entrenamientos',
        myWorkoutPrograms: 'Programas',
        masterclasses: 'Masterclass',
        podcasts: 'Podcasts',
        liveEvents: 'Eventos',
        myPlans: 'Precios de Mis Planes',
        messages: 'Mensajero',
        medias: 'Medios',
        gallery: 'Galería',
        recipes: 'Recetas',
        creators: 'Mis creadores',
        subscribers: 'Mis suscriptores',
        followers: 'My followers',
        accountManager: {
            management: "Gestión",
            posts: "Publicaciones",
        }
    },
    creator: {
        title: {
            settings: 'Tus ajustes de perfil',
        },
        form: {
            menu: {
                language: 'Idioma preferido',
                personal: 'Información personal',
                cover: 'Imagen de portada',
                landing: 'Video de bienvenida',
                password: 'Contraseña',
                social: 'Redes sociales',
                module: 'Módulos',
                messenger: 'Mensajero',
                availability: 'Disponibilidad',
                plan: 'Mi plan',
                perk: 'Ventajas de mis planes',
                video: 'Video privadas',
                faq: 'Mis Preguntas Frecuentes',
            },
            avatar: {
                edit: 'Editar avatar',
                description: 'Tamaños de avatar en píxeles: mínimo 200x200 - máximo 1080x1080',
                message: {
                    success: 'Tu avatar se ha subido con éxito.',
                },
            },
            headerImg: {
                edit: 'Editar imagen de encabezado',
                message: {
                    success: 'Tu imagen de portada se ha subido con éxito.',
                    removeConfirmation: '¿Estás seguro de que deseas eliminar tu imagen de portada?',
                    removeSuccess: 'Tu imagen de portada se ha eliminado con éxito.',
                    removeError: 'Hubo un error al eliminar tu imagen de portada.',
                },
                button: {
                    remove: 'Eliminar',
                },
            },
            personalInfo: {
                title: 'Tu información personal',
                contactUsIfYouSpotAMistake: 'Contáctanos si encuentras un error',
                username: {
                    placeholder: 'Nombre de usuario',
                    label: 'Nombre de usuario',
                },
                slug: {
                    placeholder: 'Slug',
                    label: 'Slug',
                    formatError:
                        'Tu slug debe comenzar con letras, solo puede contener letras minúsculas, números y guiones.',
                },
                fullName: {
                    placeholder: 'Nombre completo',
                    label: 'Nombre completo',
                },
                email: {
                    placeholder: 'Correo electrónico',
                    label: 'Correo electrónico',
                },
                about: {
                    placeholder: 'Acerca de',
                    label: 'Acerca de',
                },
                bio: {
                    placeholder: 'Biografía',
                    label: 'Biografía',
                },
                birthdate: {
                    placeholder: 'Fecha de nacimiento',
                    label: 'Fecha de nacimiento',
                },
                gender: {
                    placeholder: 'Género',
                    label: 'Género',
                },
                phone: {
                    placeholder: 'Teléfono',
                    label: 'Teléfono',
                },
                website: {
                    placeholder: 'Sitio web',
                    label: 'Sitio web',
                },
                button: {
                    submit: {
                        label: 'Guardar mi información personal',
                    },
                },
                message: {
                    success: 'Tu información personal se ha actualizado con éxito.',
                },
            },
            landingVideo: {
                title: 'Tu video de página de bienvenida',
                uploadVideo: {
                    label: 'Subir video',
                },
                currentVideo: {
                    label: 'Video actual',
                },
                remove: {
                    label: 'Eliminar video de bienvenida',
                },
                button: {
                    submit: {
                        label: 'Subir tu video de página de bienvenida',
                    },
                    removeConfirmation: {
                        label: 'Eliminar',
                    },
                },
                message: {
                    noFileSelected: 'Por favor, selecciona al menos un archivo de video',
                    error: 'Hubo un error al cargar tu video',
                    success: 'Tu video se ha subido con éxito y ahora se está procesando.',
                    noVideoToRemove: 'No hay video de bienvenida para eliminar',
                    removeError: 'Hubo un error al eliminar tu video de bienvenida.',
                    removeSuccess: 'Tu video de bienvenida se ha eliminado con éxito.',
                    removeLandingVideoConfirmation: '¿Estás seguro de que deseas eliminar tu video de bienvenida?',
                },
            },
            password: {
                title: 'Cambiar mi contraseña',
                oldPassword: {
                    placeholder: 'Contraseña anterior',
                    label: 'Contraseña anterior',
                },
                password: {
                    placeholder: 'Contraseña',
                    label: 'Contraseña',
                },
                passwordConfirmation: {
                    placeholder: 'Confirmación de contraseña',
                    label: 'Confirmación de contraseña',
                },
                button: {
                    submit: {
                        label: 'Cambiar mi contraseña',
                    },
                },
            },
        },
    },
    superAdmin: {
        title: {
            list: 'Creadores',
            add: 'Agregar creador',
        },
        form: {
            list: {
                search: {
                    label: 'Buscar...',
                },
                username: {
                    label: 'Nombre de usuario',
                },
                fullName: {
                    label: 'Nombre completo',
                },
                status: {
                    label: 'Estado',
                },
                createdAt: {
                    label: 'Creado el',
                },
                subscribers: {
                    label: 'Suscriptores',
                },
                button: {
                    addCreator: {
                        label: 'Agregar creador',
                    },
                    edit: {
                        label: 'Editar',
                    },
                    block: {
                        label: 'Bloquear para revisión',
                    },
                    active: {
                        label: 'Establecer como activo',
                    },
                    reviewed: {
                        label: 'Establecer como revisado',
                    },
                    banned: {
                        label: 'Banear al creador',
                    },
                    impersonate: {
                        label: 'Suplantar identidad',
                    },
                },
            },
            add: {
                title: 'Agregar un nuevo KOL',
                username: {
                    placeholder: 'Nombre de usuario',
                    label: 'Nombre de usuario',
                },
                slug: {
                    placeholder: 'Slug',
                    label: 'Slug',
                    formatError:
                        'Tu slug debe comenzar con letras, solo puede contener letras minúsculas, números y guiones.',
                },
                fullName: {
                    placeholder: 'Nombre completo',
                    label: 'Nombre completo',
                },
                email: {
                    placeholder: 'Correo electrónico',
                    label: 'Correo electrónico',
                },
                about: {
                    placeholder: 'Acerca de',
                    label: 'Acerca de',
                },
                bio: {
                    placeholder: 'Biografía',
                    label: 'Biografía',
                },
                birthdate: {
                    placeholder: 'Fecha de nacimiento',
                    label: 'Fecha de nacimiento',
                },
                gender: {
                    placeholder: 'Género',
                    label: 'Género',
                },
                phone: {
                    placeholder: 'Teléfono',
                    label: 'Teléfono',
                },
                website: {
                    placeholder: 'Sitio web',
                    label: 'Sitio web',
                },
                contentType: {
                    placeholder: 'Tipo de contenido',
                    label: 'Tipo de contenido',
                },
                site: {
                    placeholder: 'Seleccionar un sitio',
                    label: 'Sitio',
                },
                country: {
                    placeholder: 'Seleccionar un país',
                    label: 'País',
                    helper: 'Esto es extremadamente importante para Stripe. NO SE PUEDE EDITAR.',
                },
                button: {
                    cancel: {
                        label: 'Cancelar',
                    },
                    submit: {
                        label: 'Enviar',
                    },
                },
            },
            edit: {
                search: 'Buscar...',
                columnMember: 'Nombre',
                columnCreatedAt: 'Creado en',
                columnEmail: 'Correo electrónico',
                editMember: 'Editar miembro',
                impersonate: 'Suplantar identidad',
                success: 'El KOL se actualizó con éxito.',
            },
            globalSettings: {
                site: 'Sitio',
                siteHelper: 'Selecciona un sitio',
                country: 'País',
                countryPlaceholder: 'Selecciona un país',
                countryHelper: 'Esto es extremadamente importante para Stripe. NO SE PUEDE EDITAR.',
                preferredLanguage: 'Idioma preferido',
                subscriptionFees: 'Tarifas de suscripción (en %)',
                videoCallFees: 'Tarifas de llamadas de video (en %)',
                featuredAt: 'Destacado en',
                isFeatured: '¿Destacado?',
                enableNewSubscriberNotification: 'Notificación de nuevo suscriptor',
                enableNewLikeNotification: 'Notificación de nuevo me gusta',
                enableNewCommentNotification: 'Notificación de nuevo comentario',
                enableNewTipNotification: 'Notificación de nuevo consejo',
                enableNewMentionNotification: 'Notificación de nueva mención',
                notifySubscribersNewPost: 'Notificar a los suscriptores sobre nuevas publicaciones',
                notifySubscribersLiveStream: 'Notificar a los suscriptores sobre transmisiones en vivo',
                isCountSubscribersHidden: 'Ocultar contador de suscriptores',
                isCountryHidden: 'Ocultar país',
                isBirthdateHidden: 'Ocultar fecha de nacimiento',
                isFullNameHidden: 'Ocultar nombre completo',
                isOnlineStatusHidden: 'Ocultar estado en línea',
                enableModeration: 'Moderación',
                errors: {
                    invalidPayoutPercent: 'Ingresa un porcentaje de tarifa válido (1 - 90)',
                },
            },
            messengerSettings: {
                disableSending: 'Desactivar envío',
                allowUsersToSendMedia: 'Permitir a los usuarios enviar medios',
                limitNumberOfMessagesPerMonth: 'Limitar número de mensajes por mes',
                limitNumberOfCharacters: "Limite el número de caracteres por mensaje",
                maxNumberOfMessagesPerMonth: 'Máximo número de mensajes por mes',
                maxCharacterCountPerMessage: 'Máximo de caracteres por mensaje',
                invalidNumber: 'Por favor, ingresa un número válido',
                success: 'Ajustes de mensajero actualizados con éxito',
                error: 'Ocurrió un error al actualizar los ajustes de mensajero',
            },
        },
        message: {
            edit: {
                success: 'El creador se actualizó con éxito',
                error: 'El creador no se actualizó, inténtalo de nuevo',
            },
            add: {
                success: 'El creador se creó con éxito',
                error: 'El creador no se creó, inténtalo de nuevo',
            },
        },
        menu: {
            account: 'Cuenta',
            dashboard: 'Tablero',
            personal: 'Información personal',
            globalSettings: 'Ajustes globales',
            messengerSettings: 'Ajustes de mensajero',
            subscription: 'Suscripción',
            plans: 'Planes',
            subscribers: 'Suscriptores',
            privacySecurity: 'Privacidad y Seguridad',
            privacy: 'Privacidad',
            password: 'Contraseña',
        },
    },
    landing: {
        "amazing": "{creator} está creando algo increíble!",
        "backSoon": "¡Vuelve pronto!",
        "takeMeHome": "Llévame a casa",
        startExperience: 'Comenzar experiencia',
        youAreSubscribed: '¡Estás suscrito!',
        billed: 'Facturado',
        save: 'Ahorrar',
        mostPopular: 'MÁS POPULAR',
        interval: {
            monthly: 'Mensual',
            month: 'Mensual',
            quarterly: 'Trimestral',
            yearly: 'Anual',
            year: 'Anual',
        },
        login: 'Iniciar sesión',
        logout: 'Cerrar sesión',
        global: {
            about: 'Acerca de',
            faqs: 'Preguntas frecuentes',
            gallery: {
                title: 'Galería de {name}',
                startExperience: 'Comenzar experiencia',
            },
            selectSubscriptionPlan: 'Selecciona un plan de suscripción',
        },
    },
    payment: {
        defaultPerks: {
            1: "Desbloquea todo mi contenido",
            2: "Contenido exclusivo recurrente",
            3: "Acceso a mi mensajería privada"
        },
        global: {
            price: 'Premio',
            included: 'Incluido',
            plan: "{creator}'s Plan",
            landingPage: 'Página de inicio',
            online: 'en línea',
            startingSoon: '¡Hola! Estás a 1 minuto de comenzar una nueva experiencia conmigo. ¡Únete ahora!',
            startingSoonPayment: '¡Hola! Todavía no estoy listo para aceptar suscripciones. ¡Vuelve pronto!',
            rightTitle: 'Registro y Pago',
            rightTitleStep2: 'Ingresa la información de tu tarjeta',
            leftTitle: 'Plan de Suscripción Seleccionado',
            yourPaymentInformation: 'Tu información de pago',
            endingWith: 'terminando en',
            secure: 'Pago 100% seguro',
            logo: 'Logo Ikonstar',
            subLogo: 'Ikonstar',
            poweredBy: 'Desarrollado por',
            success: {
                logoAlt: 'Ikonstar',
                title: '¡Felicidades!',
                subTitle: 'Tu pago se ha realizado con éxito.',
                content: '',
                goToLogin: 'Iniciar sesión con mi cuenta',
                goToDashboard: 'Ir a mi panel de control',
            },
            links: {
                privacy: 'Política de privacidad',
                terms: 'Términos de servicio',
                termsUse: 'Términos de use',
                billing: 'Términos de facturación',
            },
            interval: {
                monthly: 'mes',
            },
        },
        form: {
            join: 'UNIRSE A MÍ AHORA!',
            joinHelper: 'Bienvenido al perfil{br}de {creator}!',
            tcs: 'Estoy de acuerdo con los {0}',
            tcsRequired: 'Debes aceptar los términos de uso',
            username: {
                label: `Nombre de usuario`,
                placeholder: `Nombre de usuario`,
            },
            firstName: {
                label: 'Nombre',
                placeholder: 'Nombre',
                customError: 'El nombre es un campo obligatorio'
            },
            lastName: {
                label: 'Apellido',
                placeholder: 'Apellido',
                customError: 'El apellido es un campo obligatorio'
            },
            name: {
                label: 'Nombre y apellido',
                placeholder: 'Nombre y apellido',
                customError: 'El nombre y apellido es un campo obligatorio'
            },
            email: {
                label: 'Correo electrónico',
                placeholder: 'Correo electrónico',
            },
            country: {
                label: 'País',
                placeholder: 'País',
            },
            postalCode: {
                label: 'Código postal',
                placeholder: 'Código postal',
            },
            button: {
                join: 'Únete ahora',
                submitStep1: {
                    label: 'Tarjetas de Crédito y Débito',
                },
                submitStep2: {
                    label: 'Validar mi pago',
                },
            },
        },
    },
    post: {
        isPinned: "Publicación fijada",
        repost: "Repostear",
        repostContent: "¡Mira esta increíble publicación!",
        doNotClose: 'NO CIERRE ESTA PÁGINA AL CARGANDO MEDIOS',
        previewTab: {
            all: 'TODOS',
            sub: 'SUSCRITO(A)',
            notSub: 'NO SUSCRITO(A)',
        },
        form: {
            add: {
                postTitle: 'Nueva publicación',
                post: 'Publicación',
                postTeaser: 'Avance de la publicación para usuarios no suscritos',
                teaserMandatory: 'obligatorio con video *',
                teaserVideo: 'Puedes subir imágenes o vídeos. La duración máxima del vídeo es de 1 minuto.',
                releaseLater: 'Programar publicación',
                access: {
                    title: 'Quién tiene acceso',
                    everyone: 'Disponible para todos',
                    free: 'Disponible para todos',
                    subscriber: 'Solo suscriptores',
                    subOtp: 'Suscriptores o pago único',
                    otp: 'Pago único',
                },
                postPrice: 'PRECIO DEL POST',
                enableComment: 'Activar comentarios ?',
                disableComment: 'Desactivar comentarios',
                isPinned: "¿Fijar la publicación en la parte superior?",
                formTitle: 'Crea tu nuevo post',
                titleNewEmpty: 'Todavía no tienes ningún post.',
                helperNewEmpty: 'Puedes cargar de la siguiente manera.',
                titleNewPost: 'Nueva publicación',
                action1: 'Sube un audio',
                action2: 'Sube una foto/video',
                action3: 'Haz una encuesta',
                action4: 'Escribe un texto',
                modalTitle: {
                    post: 'Escribe un texto',
                    audio: 'Agregar un audio',
                    photo: 'Agregar una foto/video',
                },
                modalHelper: {
                    content: {
                        post: 'Escribe tu texto',
                        audio: 'Descripción',
                        photo: 'Descripción',
                    },
                },
                poll: {
                    toggle: 'Encuesta',
                    add: '+ Agregar una opción',
                    question: 'Pregunta de la encuesta',
                    option: 'Opciones de la encuesta',
                    expirationAt: 'Periodo de expiración',
                    questionError: '¡La pregunta o el medio son obligatorios si seleccionas una encuesta!',
                    answersError: 'Las respuestas de la encuesta no pueden estar vacías',
                },
                title: 'Mi título',
                content: 'Escribe algo',
                price: '¿Agregar un precio?',
                file: 'Agregar un medio',
                titleHelper: 'Título',
                priceHelper: 'Precio',
                mediaHelper: 'Medio',
                publish: 'Publicar',
                preview: 'Visualizar Publicación',
                previewDisabled: 'La vista previa está deshabilitada mientras se codifican los videos. Por favor regresa en un momento.',
                labelIdle: 'Haz clic para seleccionar o suelta un archivo aquí...',
                messageFileNotAllowed: 'Tipo de archivo no permitido',
                success: '¡El post se ha creado con éxito!',
                error: 'El post no se ha creado, ¡inténtalo de nuevo!',
                contentRequired: 'El contenido',
                titleRequired: 'El título',
                priceFormat: 'El precio',
                mediaRequired: 'Se requiere un medio',
                saving: 'Guardando...',
                mention: 'Creará un enlace a este creador :',
            },
            edit: {
                postTitle: 'Editar un post',
                post: 'PUBLICAR',
                postTeaser: 'PUBLICAR AVANCE',
                releaseLater: 'PUBLICAR MÁS TARDE',
                media: 'Medio actual',
                publish: 'Publicar',
                access: {
                    title: 'QUIÉN TIENE ACCESO',
                    everyone: 'Disponible para todos',
                    subscriber: 'Solo suscriptores',
                    subOtp: 'Suscriptores o pago único',
                    otp: 'Pago único',
                },
                postPrice: 'PRECIO DEL POST',
                disableComment: 'DESACTIVAR COMENTARIOS',
                poll: {
                    toggle: 'Encuesta',
                    add: '+ Agregar una opción',
                    question: 'PREGUNTA DE LA ENCUESTA',
                    option: 'OPCIONES DE LA ENCUESTA',
                    expirationAt: 'PERIODO DE EXPIRACIÓN',
                    questionError: '¡La pregunta o el medio son obligatorios si seleccionas una encuesta!',
                    answersError: 'Las respuestas de la encuesta no pueden estar vacías',
                },
                content: 'Escribe algo',
                file: 'Agregar un medio para cambiar el actual',
                modalTitle: {
                    post: 'Actualizar un texto',
                    audio: 'Actualizar un audio',
                    photo: 'Actualizar una foto/video',
                },
                modalHelper: {
                    content: {
                        post: 'Escribe tu texto',
                        audio: 'Descripción',
                        photo: 'Descripción',
                    },
                },
                changeMedia: 'Debe eliminar los medios existentes antes',
                deleteMedia: {
                    title: '¿Estás seguro de que quieres eliminar el medio?',
                    cancelDelete: 'Cancelar',
                    confirmDelete: '¡Sí, hazlo!',
                    success: '¡El medio se eliminó con éxito!',
                    error: 'El medio no se eliminó, ¡inténtalo de nuevo!',
                },
                saving: 'Guardando...',
                success: '¡El post se ha actualizado con éxito!',
                error: 'El post no se ha actualizado, ¡inténtalo de nuevo!',
            },
            update: {
                success: '¡El post se ha actualizado con éxito!',
            },
            comment: {
                max: 'El comentario contener más de {max} caracteres',
                add: 'Enviar un comentario',
                edit: 'Editar su comentario',
                success: '¡El comentario se ha creado con éxito!',
                previewMode: "No puedes comentar en vista previa"
            },
            title: 'Quiero publicar algo',
            publish: 'Publicar',
            videoTeaserError: 'Al cargar una publicación de video solo para suscriptores, es obligatoria una imagen de avance',
            maxTeaserVideoDuration: 'El vídeo para usuarios no suscritos esta limitado a 1 minuto',
        },
        unlock: 'Desbloquear el contenido',
        online: 'En línea',
        like: 'Me gusta',
        liked: 'Me gusta',
        comment: 'Comentar',
        replies: {
            action: 'Responder',
            buttonAdd: 'Nueva respuesta',
            show: 'Mostrar respuestas ({count})',
            hide: 'Ocultar respuestas',
        },
        edit: 'Editar',
        delete: 'Eliminar',
        pin: 'Fijar en su perfil',
        unpin: 'Desfijar de su perfil',
        titleDelete: '¿Estás seguro de que quieres eliminar?',
        confirmDelete: 'Eliminarlo',
        cancelDelete: 'Cancelar',
        deleteSuccess: 'Tu post se eliminó con éxito.',
        deleteError: 'Tu post no se eliminó, inténtalo de nuevo.',
        today: 'Hoy',
        yesterday: 'Ayer',
        unlockContent: 'Desbloquear el contenido',
        title: 'Publicación de {name}',
        poll: {
            expires: 'expira ',
            expired: 'caducado',
        },
        readMore: {
            false: 'Leer más',
            true: 'Cerrar',
        },
        buttonCollapse: {
            more: '+ ver más',
            less: '- ver menos',
        },
        notReleased: 'Publicaciones programadas',
    },
    plan: {
        plans: {
            monthly: {
                title: 'Mensual',
                interval: 'Cada mes',
            },
            month: {
                title: 'Mensual',
                interval: 'Cada mes',
            },
            quarterly: {
                title: 'Trimestral',
                interval: 'Cada 3 meses',
            },
            yearly: {
                title: 'Anual',
                interval: 'Cada 12 meses',
            },
            year: {
                title: 'Anual',
                interval: 'Cada 12 meses',
            },
        },
        form: {
            list: {
                title: 'Cambiar mi plan',
                status: {
                    title: 'Estado',
                    values: {
                        enabled: 'Activo',
                        disabled: 'Inactivo',
                    },
                },
                button: {
                    edit: {
                        label: 'Editar plan',
                    },
                },
            },
            edit: {
                title: 'Cambiar mi plan',
                price: {
                    label: 'Precio',
                    formatError: 'Formato de precio no válido.',
                },
                isEnabled: {
                    label: 'Habilitado',
                },
                button: {
                    submit: {
                        label: 'Guardar',
                    },
                },
                message: {
                    success: 'Tu plan se ha actualizado correctamente.',
                },
            },
        },
    },
    planPerk: {
        title: {
            edit: 'Cambiar mis ventajas del plan',
        },
        form: {
            add: {
                title: 'Agregar una nueva ventaja del plan',
                text: {
                    placeholder: 'Descripción',
                    label: 'Descripción',
                },
                message: {
                    success: 'Tu ventaja del plan se ha agregado correctamente.',
                    error: 'Tu ventaja del plan no se ha agregado correctamente.',
                },
            },
            edit: {
                title: 'Editar una ventaja del plan',
                text: {
                    placeholder: 'Descripción',
                    label: 'Descripción',
                },
                button: {
                    submit: {
                        label: 'Guardar',
                    },
                    cancel: {
                        label: 'Cancelar',
                    },
                    edit: {
                        label: 'Editar {lang}',
                    },
                },
                message: {
                    success: 'Tu ventaja del plan se ha actualizado correctamente.',
                    error: 'Tu ventaja del plan no se ha actualizado correctamente.',
                },
            },
            delete: {
                title: 'Eliminar una ventaja del plan',
                button: {
                    submit: {
                        label: 'Sí, eliminarlo',
                    },
                    cancel: {
                        label: 'Cancelar',
                    },
                },
                message: {
                    success: 'Tu ventaja del plan se ha eliminado correctamente.',
                    error: 'Tu ventaja del plan no se ha eliminado correctamente.',
                },
            },
        },
    },
    privateVideoCall: {
        title: {
            list: 'Cambiar mis llamadas de video privadas',
        },
        button: {
            add: 'Crear nueva llamada de video privada',
            cancel: 'Cancelar',
        },
        form: {
            fields: {
                timeSlot: {
                    title: 'Duración de la llamada (en minutos)',
                    label: '30',
                    name: 'Duración de la llamada',
                },
                price: {
                    title: 'Precio',
                    label: '99.99',
                    name: 'Precio',
                },
                timeReminder: {
                    title: 'Recordatorio de tiempo (en minutos)',
                    label: '10',
                    name: 'Recordatorio de tiempo',
                },
                action: {
                    title: '',
                },
            },
            list: {
                timeSlot: 'Franja horaria',
                timeReminder: 'Recordatorio',
                price: 'Precio',
                edit: 'Editar',
                delete: 'Eliminar',
            },
            add: {
                title: 'Crear nueva llamada de video privada',
                submit: 'Crear',
                success: 'La llamada de video privada se ha creado con éxito.',
                error: 'Hubo un error al crear tu llamada de video privada.',
            },
            edit: {
                title: 'Editar una llamada de video privada',
                submit: 'Guardar',
                success: 'La llamada de video privada se ha editado con éxito.',
                error: 'Hubo un error al actualizar tu llamada de video privada.',
            },
            delete: {
                title: '¿Realmente quieres eliminar esta llamada de video privada?',
                submit: 'Sí, eliminar',
                success: 'La llamada de video privada se ha eliminado correctamente.',
                error: 'Hubo un error al eliminar tu llamada de video privada.',
            },
        },
    },
    liveStreaming: {
        start: "Transmitir en vivo",
        publish: {
            welcome: '¡Hola a todos, disfruten la transmisión!',
        },
        helper: {
            notPublished: 'Todavía no estás en vivo, ¡puedes elegir tus opciones antes de publicar en vivo!',
            comments: 'Si desactivas los comentarios, no será posible activarlos durante la transmisión en vivo.',
            chat: '5min inactivo se eliminará en vivo y chat',
            chatBox: 'El gato se encontrará aquí',
            audio: 'El creador ha silenciado su micrófono, volverá pronto...',
            video: 'El creador ha apagado su cámara, volverá pronto...',
            visibility: {
                subscriber: 'El directo sólo es visible para tus suscriptores, puedes abrirlo para todos haciendo clic en',
                free: 'El directo estará abierto a todos, puedes reservarlo para tus suscriptores haciendo clic en',
            },
        },
        chat: {
            helper: 'Has sido silenciado/a',
            muted: '¡ha sido silenciado/a!',
            unmuted: '¡ha sido activado el sonido!',
            joined: '¡se ha unido!',
            leaved: '¡ha abandonado!',
        },
        hangUp: {
            title: '¿Estás seguro de que quieres detener la transmisión en vivo?',
            confirm: 'Sí, hazlo',
            cancel: 'Cancelar',
        },
        errors: {
            camera: '¿Puedes conectar una cámara para iniciar la transmisión?',
            cameraConfirm: 'Esto es hecho',
            microphone: 'Necesitas un micrófono para iniciar una transmisión',
            permission: 'Permiso denegado por el sistema, ¡verifique su privacidad para usar su cámara y micrófono!',
        },
    },
    payout: {
        title: 'Solicitud de pago',
        currentSolde: 'Tu saldo disponible actual es',
        yourAccountManager: 'Tu gestor de cuentas',
        submit: 'Solicitar un pago',
        success: 'Tu solicitud de pago se ha realizado correctamente.',
        showMore: 'Ver todo el historial de pagos',
        form: {
            fields: {
                file: {
                    title: 'Importar tu factura',
                    label: 'Importar tu factura',
                    name: 'factura',
                    placeHolder: 'Subir tu factura',
                },
                date: {
                    title: 'Fecha',
                },
                amount: {
                    title: 'Cantidad',
                },
                invoiceNumber: {
                    title: 'Número de factura',
                },
                action: {
                    title: '',
                },
            },
        },
    },
    statistic: {
        title: 'Estadísticas',
        choosePeriod: 'Selecciona el período',
        stats: {
            amountEarned: {
                title: 'Ganaste',
            },
            subscribedNumber: {
                title: 'Número de suscriptores',
            },
        },
    },
    coverImage: {
        title: {
            edit: 'Tu imagen de portada',
        },
        form: {
            edit: {
                change: {
                    label: 'Cambiar tu imagen de portada',
                    placeholder: 'Haz clic para seleccionar o arrastra un archivo aquí',
                },
                currentCover: {
                    label: 'Portada actual',
                    error: {
                        required: 'Se requiere un archivo',
                    },
                },
                button: {
                    submit: {
                        label: 'Guardar tu imagen de portada',
                    },
                },
                message: {
                    success: 'Tu portada se ha cargado correctamente.',
                    error: 'Hubo un error al cargar tu portada.',
                },
            },
        },
    },
    socialNetwork: {
        title: {
            edit: 'Cambiar mis redes sociales',
        },
        form: {
            edit: {
                title: 'Cambiar mi red social',
                type: {
                    label: 'Red social',
                },
                username: {
                    label: 'Nombre de usuario',
                },
                link: {
                    label: 'Enlace',
                },
                button: {
                    edit: {
                        label: 'Editar red social',
                    },
                    remove: {
                        label: 'Eliminar',
                    },
                    add: {
                        label: 'Crear red social',
                    },
                    submit: {
                        label: 'Guardar',
                    },
                },
                message: {
                    success: 'Tu red social se ha actualizado correctamente.',
                },
            },
            add: {
                title: 'Crear una red social',
                type: {
                    label: 'Red social',
                },
                username: {
                    label: 'Nombre de usuario',
                },
                link: {
                    label: 'Enlace',
                },
                button: {
                    submit: {
                        label: 'Crear',
                    },
                },
                message: {
                    success: 'La red social se ha creado correctamente.',
                },
            },
            delete: {
                title: 'Eliminar una red social',
                button: {
                    submit: {
                        label: 'Eliminar',
                    },
                    cancel: {
                        label: 'Cancelar',
                    },
                },
                message: {
                    success: 'La red social se ha eliminado correctamente.',
                },
            },
        },
    },
    module: {
        title: {
            edit: 'Gestionar mis módulos',
        },
        form: {
            edit: {
                save: 'Guardar',
                saving: 'Guardando...',
                success: 'iMódulos se guardó exitosamente!',
                error: '¡Módulos no se guardó correctamente!',
            },
        },
    },
    messenger: {
        title: {
            edit: 'Administra mi Messenger',
        },
        form: {
            edit: {
                save: 'Guardar',
            },
        },
    },
    availability: {
        titleTimezone: 'Tu zona horaria',
        title: 'Tu disponibilidad',
        helper: 'Establece las horas en las que estás disponible',
        notAvailable: 'No estoy disponible',
        button: 'Guardar mi disponibilidad',
        success: '¡Tu disponibilidad se guardó exitosamente!',
        error: 'Tu disponibilidad no se guardó correctamente, ¡inténtalo de nuevo más tarde!',
        days: {
            mon: 'Lun',
            tue: 'Mar',
            wed: 'Mié',
            thu: 'Jue',
            fri: 'Vie',
            sat: 'Sáb',
            sun: 'Dom',
        },
        delete: {
            title: '¿Estás seguro de que quieres eliminar?',
            confirm: 'Sí, ¡hazlo!',
            cancel: 'Cancelar',
        },
    },
    onboarding: {
        title: 'Configurar tu cuenta IKONSTAR',
        welcome: 'Bienvenido/a {name}',
        thankYou: 'Gracias por unirte a {creatorName}',
        letsSetupYourAccount: 'Configuremos tu cuenta',
        form: {
            step1: {
                title: 'Cuéntanos más sobre ti',
                firstname: 'Nombre',
                lastname: 'Apellido',
                birthdate: 'Fecha de nacimiento',
                gender: 'Género',
            },
            step2: {
                title: 'Elige tus módulos',
                helper: {
                    workouts: 'Sesión de ejercicios específica dentro de un programa de entrenamiento',
                    gallery: 'Compartir fotos entre tus miembros y tú.',
                    recipes: 'Conjunto de Recetas para un Entrenamiento Completo',
                    polls: 'Incluir encuestas en tus publicaciones para tus miembros.',
                    'private-video-calls': 'Ofrecer llamadas en vivo a tus miembros.',
                    masterclass: 'Módulos de lecciones para compartir tus habilidades y conocimientos',
                    podcasts: 'Series de audio/video informativas que cubren diversos temas',
                },
            },
            step3: {
                title: '¡Vamos a lo serio!',
                username: 'Nombre de usuario',
                slug: 'Slug',
                postalCode: 'Código postal',
                country: 'País',
                timezone: 'Zona horaria',
                socials: 'Redes sociales',
            },
            step4: {
                title: 'Selecciona o personaliza tu plan',
                monthly: 'por mes',
                currency: 'Selecciona una moneda por defecto',
                custom: 'Otro precio mensual',
            },
        },
        skip: 'Saltar',
        next: 'Siguiente',
        numberTypeError: 'Por favor, introduce un número válido',
        invalidFirstname: 'Por favor, introduce un nombre válido',
        invalidLastname: 'Por favor, introduce un apellido válido',
        invalidGender: 'Por favor, elige un género válido',
        requiredGender: 'Por favor, elige un género',
        invalidUsername: 'Por favor, introduce un nombre de usuario válido',
        invalidSlug: 'Por favor, introduce un slug válido',
        invalidCountry: 'Por favor, elige un país válido',
        requiredCountry: 'Por favor, elige un país',
        invalidTimezone: 'Por favor, elige una zona horaria válida',
        requiredTimezone: 'Por favor, elige una zona horaria',
        invalidBirthdate: 'Por favor, introduce una fecha de nacimiento válida',
        requiredCurrency: 'Por favor, elige una moneda',
        invalidCurrency: 'Por favor, elige una moneda válida',
        requiredSocial: 'La red social es obligatoria',
        requiredSocialName: 'El nombre de usuario es obligatorio',
        invalidPrice: 'Por favor, introduce un precio igual o superior a 4,99',
        success: 'Tus datos de registro se han guardado con éxito',
    },
    faq: {
        title: {
            edit: 'Mis preguntas frecuentes',
        },
        form: {
            add: {
                title: 'Crear nueva pregunta (en inglés)',
                question: {
                    label: 'Pregunta',
                },
                answer: {
                    label: 'Respuesta',
                },
                button: {
                    add: {
                        label: 'Crear nueva pregunta',
                    },
                    submit: {
                        label: 'Crear',
                    },
                },
                message: {
                    success: 'La pregunta se ha creado correctamente.',
                },
            },
            edit: {
                title: 'Editar una pregunta ({lang})',
                question: {
                    label: 'Pregunta',
                },
                answer: {
                    label: 'Respuesta',
                },
                button: {
                    submit: {
                        label: 'Guardar',
                    },
                    cancel: {
                        label: 'Cancelar',
                    },
                },
                message: {
                    success: 'La pregunta se ha editado correctamente.',
                },
            },
            delete: {
                title: '¿Realmente quieres eliminar esta pregunta?',
                button: {
                    submit: {
                        label: 'Sí, eliminar',
                    },
                    cancel: {
                        label: 'Cancelar',
                    },
                },
                message: {
                    success: 'La pregunta se ha eliminado correctamente.',
                },
            },
        },
    },
    "stripeConnect": {
        "title": "¡Hola {name}, tu cuenta de socio de Stripe está ahora configurada!",
        "subtitle": "",
        "next": "¡Los usuarios ahora pueden suscribirse a tu perfil!",
        "button": "Volver al Feed"
    },
    stripeIdVerification: {
        error: 'Parece que algo salió mal. Por favor, inténtalo de nuevo.',
        form: {
            title: '¡Hola {name}, verifiquemos tu cuenta!',
            step1: 'Especificar la configuración de cuenta deseada',
            step2: 'Valide su identificación',
            step3: 'Valide su dirección',
            button: {
                next: 'Siguiente',
                previous: 'Anterior',
                verifyMe: 'Verifícame',
                submit: 'Enviar',
            },
            accountType: {
                title: 'Especifica la configuración de cuenta deseada.',
                personal: {
                    title: 'Cuenta Personal',
                    description: "Verifica como 'Cuenta Personal' si aún no tienes una empresa.",
                },
                professional: {
                    title: 'Cuenta Profesional',
                    description: 'Elige esta opción si tienes una empresa.',
                    subtitle: 'Cuéntanos más sobre tu empresa',
                },
            },
            inputs: {
                required: '{field} es obligatorio',
                company: 'Nombre de la empresa',
                company_number: 'Número de empresa',
                company_vat: 'Número de IVA de la empresa',
                address_1: 'Dirección de la empresa',
                address_2: 'Dirección de la empresa (adicional)',
                postcode: 'Código postal de la empresa',
                city: 'Ciudad de la empresa',
                country_id: 'País de la empresa',
                country: 'Elige un país',
            },
            verify: {
                title: 'Verifica tu identificación',
            },
        },
        titles: {
            created: 'Verifica tu identidad para que tu perfil sea accesible.',
            processing: 'Tu identidad está siendo verificada.',
            verified: 'Tu identidad está verificada ahora.',
            requires_input: 'Verifica tu identidad para que tu perfil sea accesible.',
            canceled: 'Verifica tu identidad para que tu perfil sea accesible.',
            redacted: 'Verifica tu identidad para que tu perfil sea accesible.',
        },
        errors: {
            consent_declined: 'El usuario ha rechazado ser verificado por Stripe. Tu cuenta no puede ser verificada sin ello.',
            under_supported_age: 'Stripe no verifica a usuarios menores de edad.',
            country_not_supported: 'Stripe no verifica a usuarios del país proporcionado.',
            document_expired: 'El documento de identidad proporcionado ha caducado.',
            document_unverified_other: 'Stripe no pudo verificar el documento de identidad proporcionado.',
            document_type_not_supported: 'El documento de identidad proporcionado no es uno de los tipos de documentos permitidos en la sesión.',
            selfie_document_missing_photo: 'El documento de identidad proporcionado no contenía una foto de un rostro.',
            selfie_face_mismatch: 'La imagen del rostro capturada no coincide con el rostro del documento.',
            selfie_unverified_other: 'Stripe no pudo verificar el selfie proporcionado.',
            selfie_manipulated: 'La imagen del rostro capturada fue manipulada.',
            id_number_unverified_other: 'Stripe no pudo verificar el número de identificación proporcionado. Consulta la lista de números de identificación admitidos.',
            id_number_insufficient_document_data: 'El documento proporcionado no contenía suficientes datos para coincidir con el número de identificación.',
            id_number_mismatch: 'La información proporcionada no pudo coincidir con las bases de datos globales.',
        },
        review: {
            title: '¡Hola {name}, la verificación de tu identidad está bajo revisión ahora',
            subtitle: 'Te informaremos del resultado por correo electrónico',
            next: 'Mientras tanto, puedes continuar con el tutorial.',
            button: 'Volver al tutorial',
        },
    },
    tutorial: {
        title: 'Progreso del Tutorial',
        complete: "¡Has completado el tutorial obligatorio!",
        stripeIdUnlock: 'Verificando tu identidad',
        stripeUnlock: 'Completa todas las demás tareas para desbloquear esta',
        stripeCountryInfo: {
            "title": "Por favor, lea con atención",
            "description": "Este formulario es uno de los pasos más importantes para configurar su cuenta. Los valores seleccionados en este paso se utilizarán para crear su cuenta Stripe Partner en Ikonstar. Estos valores no se pueden editar más tarde. Por favor, verifique cada entrada minuciosamente."
        },
        avatar: {
            "title": "¡Empecemos!",
            "content": "¡Haz clic aquí para cambiar tu avatar!"
        },
        steps: {
            title: 'Paso {nb}',
            2: 'Verificación de la cuenta',
            1: 'Completa tu perfil',
            3: '¡Listo para comenzar!',
            optional: 'Configuraciones opcionales',
        },
        todos: {
            billing_verified: 'Verifica la información de tu cuenta',
            id_verified: 'Verifica tu identidad',
            avatar: 'Sube un avatar',
            header: 'Sube una imagen de encabezado',
            about: 'Completa la sección Acerca de mí',
            bio: 'Completa la sección Mi biografía',
            socials: 'Agrega tus redes sociales a tu perfil',
            sub_perks: 'Completa tus suscripciones y ventajas',
            five_posts: 'Crea al menos 3 publicaciones',
            stripe_connect: 'Crea tu cuenta de socio de Ikonstar en Stripe',
            modules: 'Activa o desactiva módulos para tu plataforma',
            private_video_calls: 'Configura precios para las Llamadas de Video Privadas',
            messenger_settings: 'Elige tus configuraciones de Messenger',
        },
    },
    podcasts: {
        title: 'Podcasts',
        nowPlaying: 'REPRODUCIENDO AHORA',
        index: {
            empty: '¡Parece que es tu primera visita aquí!',
            createFirst: 'Configura tu página de podcasts',
            create: 'Crear un nuevo podcast',
        },
        setup: {
            title: 'Configurar mi página de podcasts',
            editButton: 'Editar configuración de la página',
            formTitle: 'Título de la página',
            formDescription: 'Descripción de la página',
            submit: 'Guardar',
            notification: {
                add: {
                    success: '¡Tu página de podcast se configuró correctamente!',
                    error: 'Tu página de podcast no se configuró, ¡inténtalo de nuevo!',
                },
                update: {
                    success: '¡Tu página de podcast se actualizó correctamente!',
                    error: 'Tu página de podcast no se actualizó, ¡inténtalo de nuevo!',
                },
            },
        },
        episode: {
            createTitle: 'Crear un nuevo Podcast',
            editTitle: 'Editar Podcast',
            saveTitle: 'Guardar Podcast',
            fieldset: 'Tu información de podcast',
            visibility: '¿Quién puede escuchar este podcast?',
            title: 'Título del podcast',
            description: 'Breve descripción del podcast',
            type: 'Tipo de podcast',
            video: 'Video del podcast',
            audio: 'Audio del podcast',
            reorder: 'Reordenar podcasts',
            applyReorder: 'Aplicar nuevo orden',
            cancelReorder: 'Cancelar',
            notification: {
                add: {
                    success: '¡Tu podcast se creó correctamente!',
                    error: 'Tu podcast no se creó, ¡inténtalo de nuevo!',
                },
                update: {
                    success: '¡Tu podcast se actualizó correctamente!',
                    error: 'Tu podcast no se actualizó, ¡inténtalo de nuevo!',
                },
                delete: {
                    success: '¡Tu podcast se eliminó correctamente!',
                    error: 'Tu podcast no se eliminó, ¡inténtalo de nuevo!',
                },
                order: {
                    success: '¡Tus podcasts se reordenaron correctamente!',
                    error: 'Tus podcasts no se reordenaron, ¡inténtalo de nuevo!',
                },
            },
        },
    },
    series: {
        episode: {
            reorder: 'Reordenar los ejercicios',
            applyReorder: 'Aplicar nuevo orden',
            cancelReorder: 'Cancelar',
            notification: {
                add: {
                    success: '¡Tu episodio se creó correctamente!',
                    error: 'Tu episodio no se creó, ¡inténtalo de nuevo!',
                },
                update: {
                    success: '¡Tu episodio se actualizó correctamente!',
                    error: 'Tu episodio no se actualizó, ¡inténtalo de nuevo!',
                },
                delete: {
                    success: '¡Tu episodio se eliminó correctamente!',
                    error: 'Tu episodio no se eliminó, ¡inténtalo de nuevo!',
                },
                order: {
                    success: '¡Tus episodios se reordenaron correctamente!',
                    error: 'Tus episodios no se reordenaron, ¡inténtalo de nuevo!',
                },
            },
        },
    },
    privacy: {
        title: {
            edit: 'Confidencialidad',
        },
        form: {
            edit: {
                visibility: 'Ocultar mi perfil en el motor de búsqueda',
                success: '¡Tus ajustes se actualizaron correctamente!',
                error: '¡Tus ajustes no se actualizaron correctamente!',
                save: 'Guardar',
            },
        },
    },
}
export default creator
