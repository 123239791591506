const statistic = {
    global: {
        title: {
            mySubscribers: 'My subscribers',
            myFollowers: 'My followers',
        },
        empty: "Looks like you don't have any subscribers yet",
        subscriber: 'Subscriber',
        subscribedSince: 'subscribed since',
    },
    columns: {
        name: 'Name',
        subscribedAt: 'Subscribed at',
        subscribedStatus: 'Subscription status',
        followedAt: 'Followed at',
        email: 'Email',
    }
}

export default statistic
